import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Form } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const inputStyle = {
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  marginBottom: "1rem",
  width: "100%",
  boxSizing: "border-box",
  padding: "0.375rem 0.75rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

const saveButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
};

export default function SalesEditModal(props) {
  const [data, setData] = React.useState(props?.data);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );

  const handleInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    axios({
      url: `${baseURL}/salesperson/update/`,
      data: {
        email: data.email ? data.email : props.data.email,
        name: data.name ? data.name : props.data.name,
      },
      headers: {
        Authorization: `Bearer ${getStoreAdminToken}`,
      },
      method: "put",
    }).then((result) => {
      toast.success("Details updated successfully!")
      props.close();
      props.fetch();
    }).catch((error) => {
      console.error(error);
      toast.error("Something's wrong, please try again later")
    })
  };

  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit SalesPerson
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data.name || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Name"
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={data.email || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Email"
                style={inputStyle}
                readOnly
              />
            </Form.Group>

            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
