import React from "react";
import DashboardCustomerCreate from "../Component/DashboardCustomerCreate";
import "./Dashboard.css";

const CustomerRegister = () => {
  return (
    <>
      <main>
        <section className="main-dashboard">
          <DashboardCustomerCreate />
        </section>
      </main>
    </>
  );
};

export default CustomerRegister;
