import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast";
import { baseURL } from "../utils/helperObj";
import { Form, Spinner } from "react-bootstrap";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const inputStyle = {
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  //   marginBottom: "1rem",
  width: "100%",
  boxSizing: "border-box",
  padding: "0.375rem 0.75rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#6c757d", // grayish background color
  color: "#fff", // white text color
  "&:hover": {
    backgroundColor: "#5a6268", // darker grayish color on hover
  },
};

const saveButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

export default function ChangePasswordModal(props) {
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );
  const [data, setData] = useState({
    old_password: "",
    new_password: "",
  });
  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handlePassChange = async () => {
    setIsLoading(true);
    let errorData = {};

    if (data.old_password === "") {
      errorData.old_password = "Please enter your old password";
    }
    if (data.new_password === "") {
      errorData.new_password = "Please enter your new password";
    }

    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
      setIsLoading(false);
      return;
    }

    // Clear the error state before making the API request
    setErrors({});

    await axios
      .post(
        `${baseURL}/change-password/`,
        {
          old_password: data.old_password,
          new_password: data.new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${getStoreAdminToken}`,
          },
        }
      )
      .then((result) => {
        props.close();
        toast.success("Password changed successfully!");
      })
      .catch((error) => {
        console.error(error);
        props.close();
        toast.error("Something's wrong, please try again later!");
      });
  };

  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Change Password
          </Typography>
          <Typography id="modal-modal-description">
            <Form.Group controlId="old_password" className="mt-1">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="text"
                name="old_password"
                value={data.old_password}
                onChange={(e) => handleInput(e)}
                // placeholder="Enter Old Password"
                style={inputStyle}
              />
              {errors.old_password && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {errors.old_password}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="new_password" className="mt-1">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                name="new_password"
                value={data.new_password}
                onChange={(e) => handleInput(e)}
                // placeholder="Enter new password"
                style={inputStyle}
              />
              {errors.new_password && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {errors.new_password}
                </p>
              )}
            </Form.Group>

            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={handlePassChange}
              >
                {isLoading ? <Spinner /> : `Save Changes`}
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
