import React from "react";
import {
  ContactForm,
  FeaturesSection,
  KeyFeatures,
  TopSection,
  WhySection,
  LandingNavbar,
  LandingFooter
} from "../Component/LandingPage";
import "../Component/LandingPage/Landing.css";

const LandingPage = () => {
  return (
    <div>
      <LandingNavbar/>
      <TopSection/>
      <WhySection />
      <KeyFeatures />
      <FeaturesSection />
      <ContactForm />
      <LandingFooter/>
    </div>
  );
};

export default LandingPage;
