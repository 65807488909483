import React from "react";
import { IoToggle, IoToggleOutline } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { TbPasswordMobilePhone } from "react-icons/tb";
import { LuClipboardEdit } from "react-icons/lu";

const SalesRepTable = ({
  salesGetData,
  handleDelClick,
  handleToggleActivation,
  handleResetPassword,
  handleSalesEdit,
}) => {
  return (
    <div className="table-inner-content table-responsive">
      <table
        className="table text-center table-bordered table-hover container"
        id="res-table-layout-admin"
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">S No.</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Status</th>
            <th scope="col">Edit Details</th>
            <th scope="col">Reset Password</th>
            <th scope="col">Delete Record</th>
          </tr>
        </thead>
        <tbody style={{borderColor: "#DEE2E6"}}>
          {salesGetData.map((val, index) => (
            <tr key={val.id}>
              <td>{index + 1}</td>
              <td>{val.name}</td>
              <td>{val.email}</td>
              <td
                onClick={() => handleToggleActivation(val.name, !val.is_active)}
              >
                {val.is_active ? (
                  <IoToggle
                    style={{
                      fontSize: "20px",
                      backgroundColor: "none",
                      color: "green",
                    }}
                  />
                ) : (
                  <IoToggleOutline
                    style={{
                      fontSize: "20px",
                      backgroundColor: "none",
                      color: "gray",
                    }}
                  />
                )}
              </td>
              <td onClick={(e) => handleSalesEdit(e, val.email, val.name)}>
                <LuClipboardEdit
                  style={{
                    fontSize: "1rem",
                    color: "#6f42c1",
                  }}
                />
              </td>
              <td onClick={(e) => handleResetPassword(val.email)}>
                <TbPasswordMobilePhone
                  style={{
                    fontSize: "1.2rem",
                    color: "#FF8C00",
                  }}
                />
              </td>
              <td onClick={(e) => handleDelClick(e, val.email)}>
                <MdDeleteForever
                  style={{
                    fontSize: "1rem",
                    color: "red",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesRepTable;
