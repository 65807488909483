import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./DashboardMainSalesCreate.css";
import { actionCreators } from "../Store/SalesAuthToken/StoreAdminAction";
import { actionCreators as SidebarAction } from "../Store/SidebarComponent/SidebarAction";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-notifications/lib/notifications.css";
import toast from "react-hot-toast";
import "./StoreAd.css";
import { baseURL } from "../utils/helperObj";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const SalesPersonLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const getStoreAdminToken = useSelector(
    (state) => state?.salesLogin?.salestoken?.access
  );
  const dispatch = useDispatch();
  const [salesLogin, setSalesLogin] = useState({
    name: "",
    password: "",
  });
  const [error, setError] = useState({
    name: "",
    password: "",
  });

  const handleIcon = () => {
    setIsOpen(!isOpen);
  }

  const handleInput = (e) => {
    e.preventDefault();
    setSalesLogin({
      ...salesLogin,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorData = {};

    if (!salesLogin.name) {
      errorData.name = "Please enter a name";
    }
    if (!salesLogin.password) {
      errorData.password = "Please enter a password";
    }

    if (Object.keys(errorData).length) {
      setError(errorData);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${baseURL}/salesperson/login/`, {
        name: salesLogin.name,
        password: salesLogin.password,
      });
      const token = response.data.tokens;
      dispatch(actionCreators.salesToken(token));
      dispatch(SidebarAction.sidebartype("sales-admin"));
      dispatch(actionCreators.setCustomerName(response.data.username));
      toast.success("Logged in successfully!", {
        position: "top-right",
        id: "toast-success",
        duration: 1000,
      });

      setTimeout(() => {
        toast.remove("toast-success");
        navigate("/customer-register", {
          state: { name: salesLogin.name, token },
        });
      }, 1000);
    } catch (error) {
      if (error.response && error.response.data) {
        const { name, password, error: apiError } = error.response.data;
        setError({
          name: name || "",
          password: password || "",
        });
        if (apiError) {
          toast.error(apiError, {
            duration: 2000,
            id: "toast-error",
          });
        }
      }
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="bg-img-page sales-login">
        <form
          className="sales-register-create"
          id="sales-login-width"
          onSubmit={handleSubmit}
        >
          <h3
            style={{
              fontSize: "18px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Sales Login
          </h3>
          <input
            type="text"
            id="name"
            name="name"
            value={salesLogin.name}
            onChange={handleInput}
            placeholder="Name"
          />
          {error.name && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.name}</p>
          )}
          <input
            type={isOpen ? `text` : `password`}
            id="sales-password"
            name="password"
            value={salesLogin.password}
            onChange={handleInput}
            placeholder="Password"
          />
           <FontAwesomeIcon
                style={{ marginRight: "2rem", cursor: "pointer" }}
                icon={isOpen ? faEye : faEyeSlash}
                className="sales-login-icon"
                onClick={handleIcon}
              />
          {error.password && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.password}</p>
          )}
          <br />
          <button
            type="submit"
            className="login-button-admin"
            disabled={isLoading}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : `Login`}
          </button>
        </form>
      </section>
    </>
  );
};

export default SalesPersonLogin;
