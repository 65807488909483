import React from "react";
import { Spinner } from "react-bootstrap";
import "./StoreAd.css";

const StoreAdForgot = ({
  storeLogin,
  handleInput,
  handleSendEmail,
  handleForgotPass,
  error,
  isLoading,
  flags,
  handleBackToLogin,
}) => {
  return (
    <form autoComplete="off">
      <div className="bg"></div>
      <div className="logo-header" style={{ width: "100%" }}>
        <img
          src="/img/wiseish.png"
          alt="wiseish-logo"
          style={{ width: "200px", height: "auto" }}
        />
      </div>
      <h5>Forgot Password</h5>
      {flags?.isEmailSent ? (
        <>
          <input
            type="text"
            id="uidToken"
            name="uidToken"
            value={storeLogin.uidToken}
            onChange={(e) => handleInput(e)}
            placeholder="UID Token"
          />
          {error.uidToken && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.uidToken}</p>
          )}
          <input
            type="text"
            id="newPass"
            name="newPass"
            value={storeLogin.newPass}
            onChange={(e) => handleInput(e)}
            placeholder="New Password"
          />
          {error.newPass && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.newPass}</p>
          )}

          <input
            type="text"
            id="confirmNewPass"
            name="confirmNewPass"
            value={storeLogin.confirmNewPass}
            onChange={(e) => handleInput(e)}
            placeholder="Confirm New Password"
          />
          {error.confirmNewPass && (
            <p style={{ color: "red", fontSize: "14px" }}>
              {error.confirmNewPass}
            </p>
          )}
          <div className="checkboxText">
            <button
              className="forgot-submit-btns"
              onClick={(e) => handleForgotPass(e)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : `Submit`}
            </button>
          </div>
        </>
      ) : (
        <>
          <input
            type="text"
            id="username"
            name="forgotEmail"
            value={storeLogin.forgotEmail}
            onChange={(e) => handleInput(e)}
            placeholder="Email"
          />
          {error.forgotEmail && (
            <p style={{ color: "red", fontSize: "14px" }}>
              {error.forgotEmail}
            </p>
          )}
          <div className="checkboxText">
            <div className="link-right-wrapper">
              <a href="" onClick={(e) => handleBackToLogin(e)}>
                Back to Login
              </a>
            </div>
            <button
              className="forgot-submit-btns"
              onClick={(e) => handleSendEmail(e)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : `Send Email`}
            </button>
          </div>
        </>
      )}
    </form>
  );
};

export default StoreAdForgot;
