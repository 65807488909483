import React from "react";
// import "./Landing.css";

const WhySection = () => {
  return (
    <div className="why-wiseish-container">
      <div className="why-circle">
        <div>Why</div>
        <div>Wiseish?</div>
      </div>
      <div className="why-content" tabIndex={0}>
        <p>
          Our platform helps you track the performance of your sales
          representatives, ensuring they are effective. You can easily record
          and manage data for both customers and prospects separately, making
          follow-ups more efficient. Send personalized messages to your contacts
          and get more Google reviews to boost your online presence. Simplify
          your sales process and drive growth with our all-in-one solution.
        </p>
      </div>
    </div>
  );
};

export { WhySection };
