import React from "react";
import "./Dashboard.css";
import Sidebarr from "../Component/Sidebarr";
import DashboardMainContent from "../Component/DashboardMainContent";

const DashboardAdmin = () => {
  return (
    <>
      <main>
        <section className="main-dashboard">
          <Sidebarr />
          <DashboardMainContent />
        </section>
      </main>
    </>
  );
};

export default DashboardAdmin;
