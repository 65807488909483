import React, { useState } from "react";
// import "./Landing.css";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";
import { baseURL } from "../../utils/helperObj";
import axios from "axios";

const ContactForm = () => {
  const [inputs, setInputs] = useState({
    name: "",
    number: "",
    email: "",
    mssg: "",
  });
  const handleInputs = (e) => {
    const input = e.target;
    setInputs({ ...inputs, [input.name]: input.value });
  };

  const handleContact = () => {
    const { name, number, email, mssg } = inputs;

    // Basic validation
    if (!name || !number || !email || !mssg) {
      toast.error("All fields are required.");
      return;
    }

    if (/\d/.test(name)) {
      toast.error("Name should not contain numbers.");
      return;
    }

    if (!/^\d{10}$/.test(number)) {
      toast.error("Mobile number should be exactly 10 digits.");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    const saveSettings = axios.post(`${baseURL}/inquiry/`, {
      name: inputs.name,
      phone_number: `91${inputs.number}`,
      email: inputs.email,
      message: inputs.mssg,
    });

    toast
      .promise(saveSettings, {
        loading: "Processing your request...",
        success: "Thank you for contacting us! We will get back to you soon.",
        error:
          "There was an issue submitting your request. Please try again later.",
      })
      .then(() => {
        setInputs({
          name: "",
          number: "",
          email: "",
          mssg: "",
        });
      })
      .catch((error) => {
        console.error("Error submitting contact form:", error);
      });
  };

  return (
    <div className="contact-form-container">
      <h2>Connect With Us</h2>
      <h4>Get in Touch with us for information and details</h4>
      <div className="contact-form">
        <input
          type="text"
          value={inputs.name}
          onChange={(e) => handleInputs(e)}
          name="name"
          placeholder="Enter your name"
          required
        />
        <input
          type="text"
          maxLength={10}
          value={inputs.number}
          onChange={(e) => handleInputs(e)}
          name="number"
          placeholder="Enter your number"
          required
        />
        <input
          type="email"
          value={inputs.email}
          onChange={(e) => handleInputs(e)}
          name="email"
          placeholder="Enter your email"
          required
        />
        <textarea
          type="textarea"
          value={inputs.mssg}
          onChange={(e) => handleInputs(e)}
          name="mssg"
          placeholder="Enter your message"
          required
        />
      </div>
      <Button
        id="contact-btn"
        variant="dark"
        className="btn btn-outline-dark"
        type="submit"
        onClick={handleContact}
      >
        Submit
      </Button>
    </div>
  );
};

export { ContactForm };
