import React, { useState } from "react";
import "./StoreAd.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { actionCreators } from "../Store/StoreAdminAuth/StoreAdminAction";
import { actionCreators as SidebarAction } from "../Store/SidebarComponent/SidebarAction";
import "react-toastify/dist/ReactToastify.css";
import { actionCreators as customerName } from "../Store/SalesAuthToken/StoreAdminAction";
import "react-notifications/lib/notifications.css";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import StoreAdForgot from "./StoreAdForgot";
import "./StoreAd.css";

const StoreAd = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flags, setFlags] = useState({
    isForgotFlag: false,
    isEmailSent: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storeLogin, setStoreLogin] = useState({
    email: "",
    password: "",
    forgotEmail: "",
    uidToken: "",
    newPass: "",
    confirmNewPass: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    forgotEmail: "",
    uidToken: "",
    newPass: "",
    confirmNewPass: "",
  });

  const handleInput = (e) => {
    e.preventDefault();

    setStoreLogin({
      ...storeLogin,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let errorData = {};

    if (
      !String(storeLogin.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errorData.email = "Please enter a valid email";
    }

    if (storeLogin.email === "") {
      errorData.email = "Please enter an email";
    }

    if (storeLogin.password === "") {
      errorData.password = "Please enter a password";
    }

    if (Object.keys(errorData).length > 0) {
      setError(errorData);
      setIsLoading(false);
      return;
    }

    setError({});
    axios({
      url: `${baseURL}/storeowner/login/`,
      data: {
        email: storeLogin?.email,
        password: storeLogin?.password,
      },
      method: "post",
    })
      .then((res) => {
        sessionStorage.setItem("storeAdminToken", res.data.tokens);
        toast.success("Logged in successfully!", {
          id: "toast-success",
          duration: 1500,
        });
        dispatch(actionCreators.masterStoreAdmin(res?.data?.tokens));
        dispatch(actionCreators.adminLoginFlag(true));
        dispatch(SidebarAction.sidebartype("owner"));
        dispatch(customerName.setCustomerName(res?.data?.store_name));
        dispatch(customerName.setStoreLocation(res?.data?.google_place_id));

        setTimeout(() => {
          toast.remove("toast-success");
          navigate("/admin");
          setIsLoading(false);
        }, 500);
      })
      .catch((error) => {
        if (error?.response && error?.response?.status === 401) {
          console.error(error);
          toast.error("Please check your credentials or try again later");
        } else {
          toast.error("Account or server temporarily unavailable.");
          console.error("API request error:", error?.message);
        }
        setIsLoading(false);
      });
  };

  const handleForgotLink = (e) => {
    e.preventDefault();
    setFlags({ ...flags, isForgotFlag: true });
  };

  const handleBackToLogin = (e) => {
    e.preventDefault();
    setFlags({ ...flags, isForgotFlag: false });
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // setDiasable(true);
    let errorData = {};
    if (
      !String(storeLogin.forgotEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errorData.forgotEmail = "Please enter a valid email";
    }
    if (storeLogin.forgotEmail === "") {
      errorData.forgotEmail = "Please enter a valid email";
    }
    if (Object.keys(errorData).length > 0) {
      setError(errorData);
      setIsLoading(false);
      return;
    }

    setError({});
    try {
      await axios.post(
        `${baseURL}/send-password-reset-email/`,
        {
          email: storeLogin.forgotEmail,
        }
      );
      setFlags({ ...flags, isEmailSent: true });
      toast.success("Password Reset link sent. Please check your Email");
    } catch (error) {
      console.error(error);
      toast.error("Something's wrong, please try again later");
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPass = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let errorData = {};
    if (storeLogin.uidToken === "") {
      errorData.uidToken = "Please enter UID Token";
    }
    if (storeLogin.newPass === "") {
      errorData.newPass = "Please enter password";
    }
    if (storeLogin.confirmNewPass === "") {
      errorData.confirmNewPass = "Please confirm new password";
    }
    if (storeLogin.newPass !== storeLogin.confirmNewPass) {
      errorData.confirmNewPass = "Passwords do not match";
    }
    if (Object.keys(errorData).length > 0) {
      setError(errorData);
      setIsLoading(false);
      return;
    }

    setError({});
    try {
      await axios.post(
        `${baseURL}/reset-password/${storeLogin.uidToken}/`,
        {
          password: `${storeLogin.newPass}`,
          confirm_password: `${storeLogin.confirmNewPass}`,
        }
      );
      toast.success("Password reset successfully");
      setFlags({ ...flags, isEmailSent: false, isForgotFlag: false });
    } catch (error) {
      console.error(error);
      toast.error("Something's wrong, check your UID or password combination");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="bg-img-page">
        <div className="login-wrapper">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        {flags?.isForgotFlag ? (
          <StoreAdForgot
            storeLogin={storeLogin}
            error={error}
            isLoading={isLoading}
            flags={flags}
            handleInput={handleInput}
            handleSendEmail={handleSendEmail}
            handleForgotPass={handleForgotPass}
            setFlags={setFlags}
            handleBackToLogin={handleBackToLogin}
          />
        ) : (
          <form autoComplete="off">
            <div className="bg"></div>
            <div className="logo-header" style={{ width: "100%" }}>
              <img
                src="/img/wiseish.png"
                alt="wiseish-logo"
                style={{ width: "200px", height: "auto" }}
              />
            </div>
            <h3
              style={{
                fontSize: "28px",
                textAlign: "left",
                fontFamily: "PT Sans",
              }}
            >
              Welcome! <b className="headingWelcomeBack"></b>
              <strong>
                <span
                  style={{
                    color: "#000",
                    fontWeight: "500",
                    fontSize: "12px",
                    marginTop: "-20px",
                    textAlign: "left",
                  }}
                >
                  Sign in to access{" "}
                  <b style={{ color: "#6442c0" }}>admin dashboard</b>
                </span>
              </strong>
            </h3>

            <input
              type="text"
              id="username"
              name="email"
              value={storeLogin.email}
              onChange={(e) => handleInput(e)}
              placeholder="Email"
            />
            {error.email && (
              <p style={{ color: "red", fontSize: "14px" }}>{error.email}</p>
            )}

            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="admin-login-pass-in"
                name="password"
                value={storeLogin.password}
                onChange={(e) => handleInput(e)}
                placeholder="Password"
              />
              <FontAwesomeIcon
                style={{ marginRight: "2rem", cursor: "pointer" }}
                icon={showPassword ? faEye : faEyeSlash}
                className="admin-login-pass-icon"
                onClick={togglePasswordVisibility}
              />
            </div>

            {error.password && (
              <p style={{ color: "red", fontSize: "14px" }}>{error.password}</p>
            )}

            <div className="checkboxText">
              <div className="link-right-wrapper">
                <a href="" onClick={(e) => handleForgotLink(e)}>
                  forgot password?
                </a>
              </div>
              <br />
              <button
                className="login-button-admin"
                onClick={(e) => handleSubmit(e)}
                disabled={isLoading}
              >
                {isLoading ? <Spinner animation="border" size="sm" /> : `Login`}
              </button>
            </div>
            <div className="login-side-img">
              <img
                src="img/40174687890.png"
                alt="footer-design-stroke"
                style={{ height: "auto", width: "100%", marginLeft: "0px" }}
              />
            </div>
          </form>
        )}
      </section>
    </>
  );
};

export default StoreAd;
