import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Form } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const inputStyle = {
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  marginBottom: "1rem",
  width: "100%",
  boxSizing: "border-box",
  padding: "0.375rem 0.75rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

const saveButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
};

export default function StoreAddModal(props) {
  const { superAuthData } = useSelector((state) => state?.superAdminData);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    store_name: "",
    email: "",
    password: "",
    confirm_password: "",
    address: "",
    phone_number: "",
    google_place_id: "",
    google_review_link: ""
  });

  const [error, setError] = useState({
    store_name: "",
    email: "",
    password: "",
    confirm_password: "",
    address: "",
    phone_number: "",
    id: "",
    google_place_id: "",
    google_review_link: ""
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && !/^\d*$/.test(value)) return;
    setData({
      ...data,
      [name]: value,
    });
    setError({ ...error, [name]: "" });
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    let errorData = {};
    if (
      !String(data.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errorData.email = "Please enter a valid email";
    }
    if (data.email === "") {
      errorData.email = "Please enter an email";
    }
    if (data.store_name === "") {
      errorData.store_name = "Please enter the store name";
    }
    if (data.address === "") {
      errorData.address = "Please enter an address";
    }
    if (data.phone_number === "") {
      errorData.phone_number = "Please enter a phone number";
    }
    if (data.password === "") {
      errorData.password = "Please enter a password";
    }
    if (data.confirm_password === "") {
      errorData.confirm_password = "Please confirm the password";
    }
    if (data.google_place_id === "") {
      errorData.google_place_id = "Please enter the google place id";
    }
    if (data.google_review_link === "") {
      errorData.google_review_link = "Please enter the google review link";
    }
    if (data.password !== data.confirm_password) {
      errorData.confirm_password = "Passwords do not match";
    }
    
    if (Object.keys(errorData).length > 0) {
      setError(errorData);
      setIsLoading(false);
      return;
    }
    setError({});

    await axios({
      url: `${baseURL}/master-admin/store-owners/`,
      data: {
        id: data.id,
        email: data.email,
        password: data.password,
        confirm_password: data.confirm_password,
        store_name: data.store_name,
        address: data.address,
        phone_number: data.phone_number,
        google_place_id: data.google_place_id,
        google_review_link: data.google_review_link
      },
      headers: {
        Authorization: `Bearer ${superAuthData.tokens.access}`,
      },
      method: "post",
    })
      .then(() => {
        toast.success("Store owner account created successfully!");
        props.close();
        props.fetch();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something's wrong, please try again later");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add StoreOwner
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <Form.Group controlId="email" className="mt-1">
              <Form.Control
                type="email"
                name="email"
                className="m-0"
                value={data.email || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter email"
                style={inputStyle}
              />
              {error.email && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>{error.email}</p>
              )}
            </Form.Group>
            <Form.Group controlId="password" className="mt-1">
              <Form.Control
                type={"text"}
                name="password"
                className="m-0"
                value={data.password || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter password"
                style={inputStyle}
              />
              {error.password && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>
                  {error.password}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="confirm_password" className="mt-1">
              <Form.Control
                type={"text"}
                name="confirm_password"
                className="m-0"
                value={data.confirm_password || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter confirm password"
                style={inputStyle}
              />
              {error.confirm_password && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>
                  {error.confirm_password}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="store_name" className="mt-1">
              <Form.Control
                type="text"
                className="m-0"
                name="store_name"
                value={data.store_name || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Store Name"
                style={inputStyle}
              />
              {error.store_name && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>
                  {error.store_name}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="google_place_id" className="mt-1">
              <Form.Control
                type="text"
                className="m-0"
                name="google_place_id"
                value={data.google_place_id || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Google Place ID"
                style={inputStyle}
              />
              {error.google_place_id && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>
                  {error.google_place_id}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="google_review_link" className="mt-1">
              <Form.Control
                type="text"
                className="m-0"
                name="google_review_link"
                value={data.google_review_link || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Google review link"
                style={inputStyle}
              />
              {error.google_review_link && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem"  }}>
                  {error.google_review_link}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="address" className="mt-1">
              <Form.Control
                type="text"
                className="m-0"
                name="address"
                value={data.address || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Address"
                style={inputStyle}
              />
              {error.address && (
                <p style={{ color: "red", fontSize: "14px" , margin: "0rem" }}>
                  {error.address}
                </p>
              )}
            </Form.Group>
            <Form.Group controlId="phone_number" className="mt-1">
              <Form.Control
                type="tel"
                className="m-0"
                name="phone_number"
                value={data.phone_number || ""}
                maxLength={10}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Phone Number"
                style={inputStyle}
              />
              {error.phone_number && (
                <p style={{ color: "red", fontSize: "14px", margin: "0rem" }}>
                  {error.phone_number}
                </p>
              )}
            </Form.Group>

            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
