import {
  salesToken,
  salesNameCustomer,
  storeLocation,
} from "./StoreAdminConstant";

let initialState = {
  salestoken: {},
  customerName: "",
  store_location: ""
  // store_location: "ChIJZVMIekzBdjkRD94521YLXxo"
  // store_location: "ChIJfXYqBQYDDTkR1d5fR87XeZo",
  // store_location: "ChIJfXYqBQYDDTkR1d5sfldfdsflmfR87XeZo",
  // store_location: "ChIJWTh4shrdKDoRcKXiT-fU6QI",
};

export const reducer = (state, action) => {
  state = state || initialState;
  if (action.type === salesToken) {
    return {
      ...state,
      salestoken: action.payload,
    };
  }

  if (action.type === salesNameCustomer) {
    return {
      ...state,
      customerName: action.payload,
    };
  }
  if (action.type === storeLocation) {
    return {
      ...state,
      store_location: action.payload,
    };
  }
  return state;
};
