import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "./StoreAd.css";
import { baseURL } from "../utils/helperObj";
import { Spinner } from "react-bootstrap";

const DashboardCustomerCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const salesPersonName = useSelector((state) => state.salesToken.customerName);
  const navigate = useNavigate();
  const [optionShopping, setOptionShopping] = useState("");
  const getStoreAdminToken = useSelector(
    (state) => state?.salesToken?.salestoken?.access
  );
  const [customerRegister, setCustomerRegister] = useState({
    name: "",
    email: "",
    phone_number: "",
    description: "",
    visit_type: "",
  });
  const [error, setError] = useState({
    name: "",
    email: "",
    phone_number: "",
    visit_type: "",
    description: "",
  });

  const handleInput = (e) => {
    e.preventDefault();
    setCustomerRegister({
      ...customerRegister,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errorData = {};

    if (
      !String(customerRegister.name)
        .toLowerCase()
        .match(/^[a-z ,.'-]+$/i)
    ) {
      errorData.name = "Please enter a valid name";
    } else if (customerRegister.name === "") {
      errorData.name = "Please enter a name";
    }

    if (
      !String(customerRegister.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errorData.email = "Please enter a valid email";
    } else if (customerRegister.email === "") {
      errorData.email = "Please enter an email";
    }

    if (customerRegister.phone_number.length !== 10) {
      errorData.phone_number = "Please enter a valid phone number";
    }

    if (optionShopping === "") {
      errorData.visit_type = "Please select a visit type";
    }

    if (customerRegister.description === "") {
      errorData.description = "Please write a feedback";
    }

    if (
      errorData.name ||
      errorData.email ||
      errorData.phone_number ||
      errorData.visit_type ||
      errorData.description
    ) {
      setError(errorData);
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(
        `${baseURL}/customer/register/`,
        {
          name: customerRegister.name,
          email: customerRegister.email,
          phone_number: `+91${customerRegister.phone_number}`,
          description: customerRegister.description,
          salesperson_name: salesPersonName,
          visit_type: optionShopping,
        },
        {
          headers: {
            Authorization: `Bearer ${getStoreAdminToken}`,
          },
        }
      );
      toast.success("Successfully registered!", {
        position: "top-right",
        id: "toast-success",
        duration: 1500,
      });
      setTimeout(() => {
        toast.remove("toast-success");
        navigate("/sales-login", {
          state: { customerRegister, salesPersonName },
        });
      }, 1000);
    } catch (error) {
      toast.error("Please check your credentials", {
        position: "top-right",
        duration: 2000,
        id: "toast-error",
      });
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShoppingType = (e) => {
    setOptionShopping(e.target.value);
    setError({ ...error, [e.target.name]: "" });
  };

  return (
    <>
      <section
        className={`Dashboard-wrapper bg-img-page customer-login`}
        style={{ height: "100vh" }}
      >
        <div className="table-data-wrapper-z123">
          <div className="bg"></div>
          <div className="table-inner-content table-responsive">
            <form
              className="sales-register-create"
              id="customer-register"
              onSubmit={handleSubmit}
            >
              <h5 style={{ textAlign: "center" }}>Customer Registration</h5>
              <div className="row">
                <div className="col-md-12 col-xs-4">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={customerRegister.name}
                    onChange={handleInput}
                    placeholder="Name"
                  />
                  {error.name && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {error.name}
                    </p>
                  )}
                </div>
                <div className="col-md-12 col-xs-4">
                  <input
                    type="text"
                    id="phone_number"
                    name="phone_number"
                    value={customerRegister.phone_number}
                    onChange={handleInput}
                    placeholder="Mobile Number"
                    maxLength={10}
                  />
                  {error.phone_number && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {error.phone_number}
                    </p>
                  )}
                </div>
                <div className="col-md-12 col-xs-4 mb-2">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={customerRegister.email}
                    onChange={handleInput}
                    placeholder="Email"
                  />
                  {error.email && (
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {error.email}
                    </p>
                  )}
                </div>
                <div className="col-md-12 col-xs-4">
                  <div className="">
                    <select
                      name="visit_type"
                      id="visit_type"
                      className="border-none"
                      onChange={handleShoppingType}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Select Visit Type
                      </option>
                      <option value="shoppers">Shopper</option>
                      <option value="visitors">Visitor</option>
                    </select>
                    {error.visit_type && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {error.visit_type}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-xs-4">
                  <div className="form-group">
                    <textarea
                      name="description"
                      value={customerRegister.description}
                      onChange={handleInput}
                      placeholder="Feedback"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"
                    />
                    {error.description && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {error.description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="login-button-admin"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  `Checkin`
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardCustomerCreate;
