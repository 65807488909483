import React from "react";

const TopSection = () => {
  
  return (
    <div id="home" className="top-section-container">
      <img className="top-img" src="/img/top-banner1.png" alt="top-banner" />
      <img className="top-img-mobile" src="/img/mob-banner-wise.jpg" alt="top-banner-mobile" />
      <div className="top-banner-content">Your personal <span> retail performance </span> and <span> customer engagement assistant</span></div>
    </div>
  );
};

export { TopSection };
