import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import StoreAd from "./Component/StoreAd";
import DashboardAdmin from "./Page/DashboardAdmin";
import SalesData from "./Page/SalesData";
import SalesRegister from "./Page/SalesRegister";
import SalesLogin from "./Page/SalesLogin";
import CustomerRegister from "./Page/CustomerRegister";
import CustomerData from "./Page/CustomerData";
import Privacy from "./policies/Privacy.js";
import CookiesPolicy from "./policies/CookiesPolicy.jsx";
import { useSelector } from "react-redux";
import TermsAndConditions from "./policies/TermsAndConditions.jsx";
import { Toaster } from "react-hot-toast";
import "./App.css";
import SuperAdminLogin from "./Page/SuperAdmin/SuperAdminLogin.jsx";
import { SuperAdminLayout } from "./Page/SuperAdmin/SuperAdminLayout.jsx";
import SuperDashboard from "./Page/SuperAdmin/SuperDashboard.jsx";
import SuperStoreOwners from "./Page/SuperAdmin/SuperStoreOwners.jsx";
import LandingPage from "./Page/LandingPage.jsx";
import Reminders from "./Page/Reminders.jsx";

function App() {
  const { isLoggedIn } = useSelector((state) => state?.storeAdminLogin);

  return (
    <div className="App">
      <Toaster />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/sales-login" element={<SalesLogin />} />
        <Route path="/customer-register" element={<CustomerRegister />} />
        {/* Admin Components */}
        <Route path="/adminlogin" element={<StoreAd />} />
        {isLoggedIn ? (
          <>
            <Route path="/admin" element={<DashboardAdmin />} />
            <Route path="/sales-data" element={<SalesData />} />
            <Route path="/sales-register" element={<SalesRegister />} />
            <Route path="/customer-data" element={<CustomerData />} />
            <Route path="/reminders" element={<Reminders />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}
        {/* Admin Components */}
        {/* Super Dashboard */}
        <Route path="/super" element={<SuperAdminLayout />}>
          <Route path="dashboard" element={<SuperDashboard />} />
          <Route path="storeowners" element={<SuperStoreOwners />} />
        </Route>
        <Route path="/superlogin" element={<SuperAdminLogin />} />
        {/* End */}
        {/* Terms Start */}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/cookies-policy" element={<CookiesPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        {/* Terms end */}
      </Routes>
    </div>
  );
}

export default App;
