import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { BarChart } from "@mui/x-charts/BarChart";
import { baseURL } from "../utils/helperObj";

const ProgressBar = () => {
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [allData, setAllData] = useState([]);
  const [salesPerformance, setSalesPerformance] = useState({
    name: ["A"],
    visitors: [1],
    shoppers: [1],
  });

  useEffect(() => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/sales-representative-visit-count/`,
        data: {},
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          const data = { name: [], shoppers: [], visitors: [] };
          result.data
            .filter((val) => val.is_active)
            .forEach((val) => {
              data.name.push(val.salesperson_name);
              data.shoppers.push(val.shopping_count);
              data.visitors.push(val.visitor_count);
            });
          setAllData(data);
          setSalesPerformance({
            name: data.name.slice(0, 10),
            shoppers: data?.shoppers?.slice(0, 10),
            visitors: data?.visitors?.slice(0, 10),
          });
          setNumberOfPages(Math.ceil(result?.data?.length / 10));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handlePageClick = async (data1) => {
    const data = {
      name: allData.name.slice(data1.selected * 10, (data1.selected + 1) * 10),
      visitors: allData?.visitors?.slice(
        data1.selected * 10,
        (data1.selected + 1) * 10
      ),
      shoppers: allData?.visitors?.slice(
        data1.selected * 10,
        (data1.selected + 1) * 10
      ),
    };
    setSalesPerformance(data);
  };

  return (
    <>
      <div className="parent-div-progressbar">
        <div className="progressbar-wrapper">
          <BarChart
            xAxis={[{ scaleType: "band", data: salesPerformance.name }]}
            series={[
              {
                data: salesPerformance.shoppers,
                color: "#125f9a",
                barWidth: 20,
              },
              {
                data: salesPerformance.visitors,
                color: "#65129a",
                barWidth: 20,
              },
            ]}
          />
        </div>
        <div className="parent-color-pillet-perform">
          <div className="color-pilot-name-customer">
            <span className="dot"></span>&nbsp;
            <p
              style={{
                fontSize: "15px",
                marginBottom: "0px",
                // color: "#a6a6a6",
                fontWeight: "600",
              }}
            >
              Shoppers &nbsp;
            </p>
          </div>
          <div className="color-pilot-name-visitor">
            <span className="dot"></span>
            &nbsp;
            <p
              style={{
                fontSize: "15px",
                marginBottom: "0px",
                // color: "#a6a6a6",
                fontWeight: "600",
              }}
            >
              Visitors
            </p>
          </div>
        </div>

        <ReactPaginate
          previousLabel={
            <i style={{ color: "#6e46c9" }} className="fas fa-angle-left"></i>
          }
          nextLabel={
            <i style={{ color: "#6e46c9" }} className="fas fa-angle-right"></i>
          }
          breakLabel={"..."}
          pageCount={numberOfPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </div>
    </>
  );
};

export default ProgressBar;
