import React, { useState } from "react";
import "../../Component/StoreAd.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { baseURL } from "../../utils/helperObj";
import { setIsSuperLogin, setSuperAuthData } from "../../Store/ToolkitReducers/SuperAdminReducer";

const SuperAdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [storeLogin, setStoreLogin] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleInput = (e) => {
    e.preventDefault();
    setStoreLogin({
      ...storeLogin,
      [e.target.name]: e.target.value,
    });
    setError({ ...error, [e.target.name]: "" });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let errorData = {};
    if (storeLogin.username === "") {
      errorData.username = "Please enter the username";
    }
    if (storeLogin.password === "") {
      errorData.password = "Please enter the password";
    }
    if (Object.keys(errorData).length > 0) {
      setError(errorData);
      setIsLoading(false);
      return;   
    }
    setError({});

    await axios({
      url: `${baseURL}/master-admin/login/`,
      data: {
        username: storeLogin?.username,
        password: storeLogin?.password,
      },
      method: "post",
    })
      .then((res) => {
        toast.success("Logged in successfully!");
        dispatch(setSuperAuthData(res.data));
        dispatch(setIsSuperLogin(true));
        navigate("/super/dashboard")
      })
      .catch((error) => {
        console.error(error);
        toast.error("Authenticated Failed, Please check your credentials");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <main className="bg-img-page">
        <div className="login-wrapper">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        <form autoComplete="off">
          <div className="bg"></div>
          <div className="logo-header" style={{ width: "100%" }}>
            <img
              src="/img/wiseish.png"
              alt="wiseish-logo"
              style={{ width: "200px", height: "auto" }}
            />
          </div>
          <h3
            style={{
              fontSize: "28px",
              textAlign: "left",
              fontFamily: "PT Sans",
            }}
          >
            Welcome! <b className="headingWelcomeBack"></b>
            <strong>
              <span
                style={{
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "12px",
                  marginTop: "-20px",
                  textAlign: "left",
                }}
              >
                Sign in to access{" "}
                <b style={{ color: "#6442c0" }}>Super Admin dashboard</b>
              </span>
            </strong>
          </h3>
          <input
            type="text"
            id="username"
            name="username"
            value={storeLogin.username}
            onChange={(e) => handleInput(e)}
            placeholder="Username"
          />
          {error.username && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.username}</p>
          )}

          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="admin-login-pass-in"
              name="password"
              value={storeLogin.password}
              onChange={(e) => handleInput(e)}
              placeholder="Password"
            />
            <FontAwesomeIcon
              style={{ marginRight: "2rem", cursor: "pointer" }}
              icon={showPassword ? faEye : faEyeSlash}
              className="admin-login-pass-icon"
              onClick={togglePasswordVisibility}
            />
          </div>

          {error.password && (
            <p style={{ color: "red", fontSize: "14px" }}>{error.password}</p>
          )}

          <div className="checkboxText">
            <br />
            <button
              className="login-button-admin"
              onClick={(e) => handleSubmit(e)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner animation="border" size="sm" /> : `Login`}
            </button>
          </div>
          <div className="login-side-img">
            <img
              src="img/40174687890.png"
              alt="footer-design-stroke"
              style={{ height: "auto", width: "100%", marginLeft: "0px" }}
            />
          </div>
        </form>
      </main>
    </>
  );
};

export default SuperAdminLogin;
