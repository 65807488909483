import React, { useState } from "react";
import axios from "axios";
import { baseURL } from "../utils/helperObj";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { CustomerModal } from "./Modals";
import { capitalize } from "../utils/helperFunctions";

const CustomerTable = ({ storeData }) => {
  const accessToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );
  const [showModal, setModal] = useState(false);
  const [record, setRecord] = useState([]);

  const fetchCustomerData = async (customer, e) => {
    e.preventDefault();
    const phoneNum = customer.phone_number.slice(1);
    const params = `customer-detail/?name=${customer.name}&email=${customer.email}&phone_number=%2B${phoneNum}`;
    try {
      const response = await axios.get(`${baseURL}/${params}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response?.data.length > 1) {
        setRecord(response.data);
        setModal(true);
      } else {
        toast.error("There is no previous record available for this customer");
      }
    } catch (error) {
      toast.error("No customer found with the provided details");
      console.log(error);
    }
  };

  return (
    <>
      {showModal && (
        <CustomerModal
          showModal={showModal}
          record={record}
          close={() => setModal(false)}
        />
      )}
      <div className="table-inner-content table-responsive">
        <table
          id="res-table-layout-admin"
          className="table text-center table-bordered table-hover container"
        >
          <thead className="thead-light">
            <tr>
              <th>S. No</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Visit Type</th>
              <th>Sales Rep</th>
              <th>Feedback</th>
            </tr>
          </thead>
          <tbody>
            {storeData &&
              storeData?.map((val, index) => (
                <tr key={val.id}>
                  <td>{index + 1}</td>
                  <td>
                    <a href="" onClick={(e) => fetchCustomerData(val, e)}>
                      {capitalize(val?.name)}
                    </a>
                  </td>
                  <td>{val?.email}</td>
                  <td>{val?.phone_number}</td>
                  <td>{capitalize(val?.visit_type)}</td>
                  <td>{val?.salesperson_name}</td>
                  <td>{val?.description}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomerTable;
