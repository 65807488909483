import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const LandingFooter = () => {
  return (
    <footer className="footer-landing pt-5">
      <Container>
        <Row>
          <Col md={5} sm={6}>
          <div style={{display: "flex", flexDirection: "column"}}>
            <img
              src="/img/landingLogo.png"
              alt="brand logo"
              width="200"
              height="80"
              className="d-inline-block align-top"
            />
            {/* <a target="_blank" href=""> */}
            <img
              src="/img/playstore.png"
              alt="brand logo"
              width="100"
              style={{margin: "0 0 1rem 3rem", cursor: "pointer"}}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.wiseish",
                  "_blank"
                )}
              className="d-inline-block align-top"
              />
              {/* </a> */}
              </div>
          </Col>
          <Col md={3} sm={6}>
            <h5>Contact Us</h5>
            <ul className="list-unstyled mb-0">
              <li>Email: info@electrogets.com</li>
              <li>Phone: +91 9310147341</li>
            </ul>
          </Col>
          <Col md={2} sm={6}>
            <h5>Policies</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wiseish.in/terms"
                >
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wiseish.in/privacy-policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wiseish.in/cookies-policy"
                >
                  Cookie Policy
                </a>
              </li>
            </ul>
          </Col>
          <Col md={2} sm={6}>
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/share/fATqkGJDAWAfutoC/?mibextid=qi2Omg"
                >
                  <i className="fab fa-facebook-f" /> Facebook
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/electrogets/"
                >
                  <i className="fab fa-linkedin" /> LinkedIn
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/electro_gets/"
                >
                  <i className="fab fa-instagram" /> Instagram
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-3">
            <p>
              &copy; {new Date().getFullYear()} Developed by Electrogets
              Technologies Private Limited. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export { LandingFooter };
