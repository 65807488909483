import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
// import "./Landing.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const LandingNavbar = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/adminlogin");
  };
  return (
    <Navbar className="bg-body-tertiary bg-nav-landing p-1" >
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt="wisiesh logo"
            src="/img/wiseishLogo.png"
            width="200"
            height="58"
            className="d-inline-block align-top nav-img"
          />{" "}
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Button variant="outline-primary" onClick={handleClick}>
            Login
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export { LandingNavbar };
