import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiPresentationFill } from "react-icons/ri";
import { actionCreators } from "../Store/SidebarComponent/SidebarAction";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getToggleSidebar = useSelector((state) => state.toggle.sidebarToggle);

  const handleDashboard = (e, value) => {
    dispatch(actionCreators.SidebarToggle(false));
    navigate(value);
  };

  return (
    <>
      <aside className={`left-sidebar ${getToggleSidebar ? "active" : ""}`}>
        <div className={`sidebar-top`}>
          <img
            src="img/logo.jpeg"
            alt="wiseish-text-logo"
            className="img-fluid"
          />
          <menu>
            <ul className="list-unstyled ">
              <li className="active">
                <div
                  onClick={(e) => handleDashboard(e, "/admin")}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fa-solid fa-clapperboard"
                    style={{ color: "#5585D6" }}
                  ></i>
                  &nbsp;&nbsp; <span className="list"> Dashboard</span>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => handleDashboard(e, "/sales-data")}
                  style={{ cursor: "pointer" }}
                >
                  <RiPresentationFill style={{ color: "#5585D6" }} />{" "}
                  &nbsp;&nbsp;
                  <span className="list">Sales Representative</span>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => handleDashboard(e, "/customer-data")}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fa-solid fa-eye"
                    style={{ color: "#5585D6" }}
                  ></i>
                  &nbsp;&nbsp;<span className="list">Customer Data</span>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => handleDashboard(e, "/reminders")}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fa fa-bell"
                    style={{ color: "#5585D6" }}
                  ></i>
                  &nbsp;&nbsp;&nbsp;<span className="list">Reminders</span>
                </div>
              </li>
            </ul>
          </menu>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
