import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseURL } from "../utils/helperObj";

const chartSetting = {
  xAxis: [
    {
      label: "",
    },
  ],
  width: 500,
  height: 400,
};
const data = [
  { month: "Jan", value: 0 },
  { month: "Jan", value: 0 },
  { month: "Feb", value: 0 },
  { month: "Mar", value: 0 },
  { month: "Apr", value: 0 },
  { month: "May", value: 0 },
  { month: "Jun", value: 0 },
  { month: "July", value: 0 },
  { month: "Aug", value: 0 },
  { month: "Sep", value: 0 },
  { month: "Oct", value: 0 },
  { month: "Nov", value: 0 },
  { month: "Dec", value: 0 },
];
const DashboardChart = () => {
  const [graphData, setGraphData] = useState(data);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );

  useEffect(() => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/monthly-registration-count/`,
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          const apiData = result?.data || {};

          const formattedData = Object.keys(apiData).map((month) => ({
            month: month.slice(0, 3), // Extract first three characters for Jan, Feb, etc.
            value: apiData[month],
          }));

          setGraphData(formattedData);
        })
        .catch((error) => {
          // redirectLogin(error)
          console.error(error);
        });
    }
  }, [getStoreAdminToken]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="color-pilot-name-visitor"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft: "1%",
          }}
        >
          <span className="dot" style={{ backgroundColor: "#125f9a" }}></span>
          &nbsp;
          <p
            style={{
              fontSize: "15px",
              marginBottom: "0px",
              // color: "#a6a6a6",
              fontWeight: "600",
            }}
          >
            Total Customer Count
          </p>
        </div>
        <BarChart
          dataset={graphData}
          yAxis={[{ scaleType: "band", dataKey: "month" }]}
          series={[
            {
              dataKey: "value",
              label: "Total Customer Count",
              color: "#125f9a",
              width: "12px !important",
              height: "12px !important",
              borderRadius: "50%",
            },
          ]}
          layout="horizontal"
          fill="#125f9a"
          //   sx = {
          //     [`.${axisClasses.left} .${axisClasses.label}`] = {
          //       transform: 'translate(-20px, 0)',
          //     }
          //   }
          {...chartSetting}
          //   margin={{ right: 50, left: 80, bottom: 30 }}
        />
      </div>
    </>
  );
};

export default DashboardChart;
