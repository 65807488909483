import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import DashbaordHeader from "./DashbaordHeader";
import DashboardCard from "./DashboardCard";
import axios from "axios";
import { Link } from "react-router-dom";
import SalesEditModal from "./SalesEditModal";
import "react-notifications/lib/notifications.css";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import "./DashboardMainSalesData.css";
import SalesDeleteModal from "./SalesDeleteModal";
import { baseURL } from "../utils/helperObj";
import SalesResetModal from "./SalesResetModal";
import SalesRepTable from "./SalesRepTable";
import { BsPersonPlus } from "react-icons/bs";

const DashboardMainSalesData = () => {
  const [editData, setEditData] = useState();
  const [delData, setDelData] = useState();
  const [resetData, setResetData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );
  const [salesGetData, setSalesGetData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [modals, setModals] = useState({
    showModal: false,
    showModalDel: false,
    showModalReset: false,
  });

  const fetchApi = () => {
    setIsLoading(true);
    axios
      .get(`${baseURL}/salespersons/`, {
        headers: { Authorization: `Bearer ${getStoreAdminToken}` },
      })
      .then((response) => {
        setAllData(response.data);
        setSalesGetData(response.data.slice(0, 10));
        setNumberOfPages(Math.ceil(response.data.length / 10));
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching sales data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (getStoreAdminToken) {
      fetchApi();
    }
  }, [getStoreAdminToken]);

  const handlePageClick = async (data1) => {
    setIsLoading(true);
    const data = allData.slice(data1.selected * 10, (data1.selected + 1) * 10);
    setSalesGetData(data.slice(0, 10));
    setIsLoading(false);
  };

  const handleDelClick = (e, value) => {
    setModals({ ...modals, showModalDel: true });
    setDelData(value);
  };

  const handleSalesEdit = (e, email, name) => {
    setModals({ ...modals, showModal: true });
    setEditData({ email, name });
  };

  const handleResetPassword = (email) => {
    setModals({ ...modals, showModalReset: true });
    setResetData(email);
  };

  const handleToggleActivation = (name, isActive) => {
    setIsLoading(true);
    axios
      .put(
        `${baseURL}/salesperson/activate-deactivate/`,
        {
          name,
          is_active: `${isActive}`,
        },
        {
          headers: { Authorization: `Bearer ${getStoreAdminToken}` },
        }
      )
      .then(() => {
        const updatedData = salesGetData.map((salesperson) => {
          if (salesperson.name === name) {
            return { ...salesperson, is_active: isActive };
          }
          return salesperson;
        });
        setSalesGetData(updatedData);
        toast.success(
          `Salesperson ${isActive ? "activated" : "deactivated"} successfully.`
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error toggling activation status:", error);
        setIsLoading(false);
      });
  };

  return (
    <>
      {modals && modals?.showModal && (
        <SalesEditModal
          showModal={modals.showModal}
          data={editData}
          close={() => setModals({ ...modals, showModal: false })}
          fetch={fetchApi}
        />
      )}
      {modals && modals?.showModalDel && (
        <SalesDeleteModal
          showModal={modals?.showModalDel}
          data={delData}
          close={() => setModals({ ...modals, showModalDel: false })}
          fetch={fetchApi}
        />
      )}
      {modals && modals?.showModalReset && (
        <SalesResetModal
          showModal={modals?.showModalReset}
          email={resetData}
          close={() => setModals({ ...modals, showModalReset: false })}
          fetch={fetchApi}
        />
      )}
      {isLoading && (
        <div className="loader-overlay">
          <Spinner />
        </div>
      )}
      <section
        className={`Dashboard-wrapper`}
        id="Dashbaord-wrapper-margin-left"
      >
        <DashbaordHeader />
        <DashboardCard />
        <div className="buttom-parent-div-sales">
          <Link to="/sales-register">
            <button
              className="button-30-add-sales-admin"
              style={{ textAlign: "right", borderRadius: "1rem" }}
            >
              <BsPersonPlus
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  margin: "0 0.5rem 0 0",
                }}
              />
              <span>Add Sales Rep</span>
            </button>
          </Link>
        </div>
        <div className="table-data-wrapper">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              padding: "10px",
              margin: "1rem",
              fontWeight: "bold",
              fontSize: "1.6rem",
            }}
          >
            Representative
          </h5>
          <SalesRepTable
            salesGetData={salesGetData}
            handleToggleActivation={handleToggleActivation}
            handleResetPassword={handleResetPassword}
            handleSalesEdit={handleSalesEdit}
            handleDelClick={handleDelClick}
          />
          <ReactPaginate
            previousLabel={
              <i style={{ color: "#6e46c9" }} className="fas fa-angle-left"></i>
            }
            nextLabel={
              <i
                style={{ color: "#6e46c9" }}
                className="fas fa-angle-right"
              ></i>
            }
            breakLabel={"..."}
            pageCount={numberOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </section>
    </>
  );
};

export default DashboardMainSalesData;
