import React from "react";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CiLogout } from "react-icons/ci";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import toast from "react-hot-toast";
import {
  setIsSuperLogin,
  setSideToggle,
  setSuperAuthData,
} from "../../Store/ToolkitReducers/SuperAdminReducer";

const SuperHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { superAuthData, sideToggle } = useSelector(
    (state) => state?.superAdminData
  );

  const handleData = () => {
    dispatch(setSideToggle(!sideToggle));
  };

  const handleLogout = () => {
    dispatch(setSuperAuthData({}));
    dispatch(setIsSuperLogin(false));
    dispatch(setSideToggle(true));
    toast.success("Logged out successfully");
    navigate("/superlogin");
  };

  return (
    <>
      <header>
        <div className="super-top-bar">
          <div
            id="top-left"
            className="top-left d-flex align-items-center justify-content-center"
          >
            <div className="mr-2 cursor-pointer" onClick={(e) => handleData(e)}>
              <BsFillMenuButtonWideFill />
            </div>
            {window.location.pathname === "/super/dashboard" && (
              <div className="fs-2 fw-bold">Super Dashboard</div>
            )}
            {window.location.pathname === "/super/storeowners" && (
              <div className="fs-2 fw-bold">Store Owners</div>
            )}
          </div>
          <div className="top-right" style={{ textAlign: "center" }}>
            <div className="avatar-wrap">
              <FaRegUser />
              &nbsp; &nbsp;
              <span className="user-name">Hello {superAuthData?.username}</span>
            </div>
            <button
              className="btn-logout-admindashboard"
              onClick={(e) => handleLogout(e)}
            >
              <CiLogout style={{ fontWeight: "bold" }} />
              <span className="logout-text-mobile m-1">Logout</span>
            </button>
          </div>
        </div>
      </header>
    </>
  );
};
export { SuperHeader };
