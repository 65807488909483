import { masterStoreLogin, adminLoginFlag } from './StoreAdminConstant';
let initialState = {
    storeAdmin: {},
    isLoggedIn: false
}
export const reducer = (state, action) => {
    state = state || initialState
    if (action.type === masterStoreLogin) {
        return {
            ...state, storeAdmin: action.payload
        }
    }

    if(action.type === adminLoginFlag){
        return {
            ...state, isLoggedIn: action.payload
        }
    }

    return state
}