import React from "react";

const SuperDashboard = () => {
  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div>Something to do here</div>
      <span>
        <select name="select" id="">
          {" "}
          Select the store owner
          <option>LG</option>
          <option>Daikin</option>
          <option>Whirlpool</option>
          <option>Vijaya Digitals</option>
        </select>
      </span>
    </div>
  );
};

export default SuperDashboard;
