import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import toast from "react-hot-toast";
import { baseURL } from "../utils/helperObj";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#6c757d", // grayish background color
  color: "#fff", // white text color
  "&:hover": {
    backgroundColor: "#5a6268", // darker grayish color on hover
  },
};

const saveButtonStyle = {
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

export default function SalesDeleteModal(props) {
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );
  const { superAuthData, isSuperLogin } = useSelector(
    (state) => state?.superAdminData
  );
  const [secondModal, setSecondModal] = useState(false);

  const handleSalesDelete = () => {
    axios({
      url: `${baseURL}/salesperson/delete/`,
      data: {
        email: props?.data,
      },
      headers: {
        Authorization: `Bearer ${getStoreAdminToken}`,
      },
      method: "delete",
    })
      .then((result) => {
        props.close();
        props.fetch();
        toast.success("Record deleted successfully!");
      })
      .catch((error) => {
        console.error(error);
        props.close();
        props.fetch();
        toast.error("Something's wrong, please try again later!");
      });
  };

  const handleStoreDelete = async () => {
    await axios({
      url: `${baseURL}/master-admin/store-owners/`,
      data: {
        id: props?.data,
      },
      headers: {
        Authorization: `Bearer ${superAuthData?.tokens.access}`,
      },
      method: "delete",
    })
      .then((result) => {
        props.close();
        props.fetch();
        toast.success("Record deleted successfully!");
      })
      .catch((error) => {
        console.error(error);
        props.close();
        props.fetch();
        toast.error("Something's wrong, please try again later!");
      });
  };

  return (
    <div>
      {secondModal && (
        <Modal
          open
          onClose={() => {
            props.close();
            setSecondModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Delete {isSuperLogin ? `Store Owner` : `SalesPerson`}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <span>
                This action will permanently delete the salesperson and all
                associated customer data.
                <br />
              </span>
              <div style={buttonContainerStyle}>
                <Button
                  variant="contained"
                  style={closeButtonStyle}
                  onClick={() => {
                    props.close();
                    setSecondModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  style={saveButtonStyle}
                  onClick={isSuperLogin ? handleStoreDelete : handleSalesDelete}
                >
                  I confirm
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>
      )}
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete {isSuperLogin ? `Store Owner` : `SalesPerson`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <span>
              Are you sure you want to delete this record?
              <br />
            </span>
            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                No
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={() => setSecondModal(true)}
              >
                Yes
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
