import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import moment from "moment";
import axios from "axios";
import DashbaordHeader from "./DashbaordHeader";
import DashboardCard from "./DashboardCard";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Button, Dropdown, Spinner, Collapse } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import "./DashboardCustomerData.css";
import { useLocation } from "react-router-dom";
import CustomerTable from "./CustomerTable";
import CustomerCalendar from "./CustomerCalendar";
import { FiDownload } from "react-icons/fi";
import { CiFilter } from "react-icons/ci";
import { IoPersonOutline } from "react-icons/io5";
import { IoMdOptions } from "react-icons/io";
import { handleExcelAdmin } from "../utils/helperFunctions";

const DashboardCustomerData = () => {
  const location = useLocation();
  const passedData = location.state?.data || [];
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [allData, setAllData] = useState(passedData);
  const [storeData, setStoreData] = useState([]);
  const [filteredData, setFilteredData] = useState(passedData);
  const [startDate, setStartDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    searchInput: "",
    salesperson_name: "",
    visit_type: "",
    start_date: null,
    end_date: null,
  });
  const [salespersons, setSalespersons] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [cardData, setCardData] = useState();

  useEffect(() => {
    if (getStoreAdminToken && allData.length === 0) {
      setIsLoading(true);
      axios({
        url: `${baseURL}/store-owner/customers/`,
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          const customers = result.data;
          setAllData(result.data);
          setFilteredData(result.data);
          setNumberOfPages(Math.ceil(result.data.length / 10));
          setStoreData(result.data.slice(0, 10));
          if (customers && customers.length > 0) {
            const lastDate = customers.reduce((latest, customer) => {
              const customerDate = new Date(customer.datetime_field);
              return customerDate < latest ? customerDate : latest;
            }, new Date(customers[0].datetime_field));
            setStartDate(lastDate);
          }
        })
        .catch((error) => {
          handleFetchError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getStoreAdminToken, navigate]);

  const handleFetchError = (error) => {
    if (error?.response?.status === 401) {
      toast.error("Session expired. Please login again.", {
        duration: 2000,
      });
      setTimeout(() => navigate("/adminlogin"), 1000);
    } else {
      toast.error("Something's wrong, please try again later");
    }
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const newData = filteredData.slice(
      selectedPage * 10,
      (selectedPage + 1) * 10
    );
    setStoreData(newData);
  };

  const handleDateRangeChange = (dateRange) => {
    setFilters({
      ...filters,
      start_date: dateRange ? dateRange[0] : null,
      end_date: dateRange ? dateRange[1] : null,
    });
  };

  const handleExcelBtn = () => {
    handleExcelAdmin(filteredData);
  };

  const handleNumChange = (e) => {
    setFilters({ ...filters, searchInput: e.target.value });
  };

  const handleFilterChange = (filter, value) => {
    setFilters({ ...filters, [filter]: value });
  };

  const fetchFilteredCustomers = async () => {
    setIsLoading(true);
    try {
      const payload = {
        ...filters,
        start_date: filters.start_date
          ? moment(filters.start_date).format("YYYY-MM-DD")
          : null,
        end_date: filters.end_date
          ? moment(filters.end_date).format("YYYY-MM-DD")
          : null,
      };

      const response = await axios.post(
        `${baseURL}/customer/search/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${getStoreAdminToken}`,
          },
        }
      );

      const searchResults = response.data.customers;
      setFilteredData(searchResults);
      setCardData(response.data);
      setStoreData(searchResults.slice(0, 10));
      setNumberOfPages(Math.ceil(searchResults.length / 10));
    } catch (error) {
      console.error(error);
      toast.error("No customers found with the provided input");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = () => {
    fetchFilteredCustomers();
  };

  const handleClearSearch = () => {
    setFilters({
      searchInput: "",
      salesperson_name: "",
      visit_type: "",
      start_date: null,
      end_date: null,
    });
    setFilteredData(allData);
    setStoreData(allData.slice(0, 10));
    setNumberOfPages(Math.ceil(allData.length / 10));
    setCardData();
    setStartDate(null); // Clear start date for the calendar
  };

  const handleSalespersonSelect = (eventKey) => {
    handleFilterChange("salesperson_name", eventKey);
  };

  useEffect(() => {
    if (getStoreAdminToken) {
      axios
        .get(`${baseURL}/salespersons/`, {
          headers: { Authorization: `Bearer ${getStoreAdminToken}` },
        })
        .then((response) => {
          setSalespersons(response.data);
        })
        .catch((error) => {
          console.error("Error fetching sales data:", error);
        });
    }
  }, [getStoreAdminToken]);

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <Spinner />
        </div>
      )}
      <section
        className={`Dashboard-wrapper`}
        id="Dashbaord-wrapper-margin-left"
      >
        <>
          <DashbaordHeader />
          <DashboardCard cardData={cardData} />
        </>
        <div className="table-data-wrapper">
          <div className="table-heading-wrapper">
            <div id="customer-data-heading">Customer Data</div>
            <Button
              variant="light"
              id="customer-options-btn"
              onClick={() => setOpen(!open)}
              aria-controls="collapse-actions"
              aria-expanded={open}
            >
              <IoMdOptions /> All filters
            </Button>
            <Collapse in={open}>
              <div id="collapse-actions">
                <div className="options-field">
                  <div className="d-flex">
                    <Button
                      variant="light"
                      style={{ backgroundColor: "#DEE2E6" }}
                      className="excel-btn mr-1"
                      onClick={handleExcelBtn}
                    >
                      <FiDownload />
                    </Button>
                  </div>
                  <div className="d-flex align-items-center">
                    <Dropdown
                      className="filter-btn-customer ml-4 mr-1"
                      onSelect={(e) => handleFilterChange("visit_type", e)}
                    >
                      <Dropdown.Toggle
                        style={{
                          backgroundColor: "#DEE2E6",
                          fontSize: "0.9rem",
                        }}
                        variant="light"
                        id="filter-dropdown"
                      >
                        <CiFilter style={{ fontSize: "1.2rem" }} />
                        {filters.visit_type.length > 0
                          ? filters.visit_type
                          : null}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="shoppers">
                          Shoppers
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="visitors">
                          Visitors
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                      className="salesperson-btn-customer mr-1"
                      onSelect={handleSalespersonSelect}
                    >
                      <Dropdown.Toggle
                        className="mr-0"
                        style={{
                          backgroundColor: "#DEE2E6",
                          fontSize: "0.9rem",
                        }}
                        variant="light"
                        id="salesperson-filter"
                      >
                        <IoPersonOutline style={{ fontSize: "0.9rem" }} />
                        {filters.salesperson_name.length > 0
                          ? filters.salesperson_name
                          : null}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {salespersons.length > 0 &&
                          salespersons.map((person) => (
                            <Dropdown.Item
                              key={person.id}
                              eventKey={person.name}
                            >
                              {person.name}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <CustomerCalendar
                      startDate={startDate}
                      onDateRangeChange={handleDateRangeChange}
                    />
                    <div className="search-number mr-1">
                      <input
                        type="text"
                        name="search"
                        onChange={handleNumChange}
                        value={filters.searchInput}
                        autoComplete="off"
                        placeholder="Search by name or number"
                      />
                    </div>
                    <Button
                      variant="secondary"
                      className="search-btn-customer ml-2"
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleSearch}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      Apply
                    </Button>
                    <Button
                      className="ml-1 mr-3 btn-outline-danger"
                      variant="light"
                      style={{ fontSize: "0.9rem" }}
                      onClick={handleClearSearch}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <CustomerTable storeData={storeData} />
          <ReactPaginate
            previousLabel={
              <i style={{ color: "#6e46c9" }} className="fas fa-angle-left"></i>
            }
            nextLabel={
              <i
                style={{ color: "#6e46c9" }}
                className="fas fa-angle-right"
              ></i>
            }
            breakLabel={"..."}
            pageCount={numberOfPages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </section>
    </>
  );
};

export default DashboardCustomerData;
