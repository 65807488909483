import React from "react";
import SalesPersonLogin from "../Component/SalesPersonLogin";
import "./Dashboard.css";

const SalesLogin = () => {
  return (
    <>
      <main>
        <section className="main-dashboard">
          <SalesPersonLogin />
        </section>
      </main>
    </>
  );
};

export default SalesLogin;
