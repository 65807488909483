import * as XLSX from "xlsx";
import { FaCalendarAlt } from "react-icons/fa";

export const handleExcelAdmin = (filteredData) => {
  const data = filteredData.map((val, index) => {
    let sno = index + 1;
    return [
      sno,
      val.id,
      val.name,
      val.email,
      val.phone_number,
      val.salesperson_name,
      val.visit_type,
      val.description,
    ];
  });
  const data1 = [
    "S no.",
    "Id",
    "Name",
    "Email",
    "Phone Number",
    "Sales Representative",
    "Visit Type",
    "Feedback",
  ];
  data.unshift(data1);
  var ws = XLSX.utils.aoa_to_sheet(data);
  var wv = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wv, ws, "demo");
  XLSX.writeFile(wv, "customers_data.xlsx");
};

export const handleExcel = (customers) => {
  const data = customers.map((val, index) => {
    let sno = index + 1;
    return [
      sno,
      val.id,
      val.name,
      val.email,
      val.phone_number,
      val.salesperson_name,
      val.visit_type,
      val.description,
    ];
  });
  const data1 = [
    "S no.",
    "Id",
    "Name",
    "Email",
    "Phone Number",
    "Sales Representative",
    "Visit Type",
    "Feedback",
  ];
  data.unshift(data1);
  var ws = XLSX.utils.aoa_to_sheet(data);
  var wv = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wv, ws, "demo");
  XLSX.writeFile(wv, "customers_data.xlsx");
};

export const findDayWithMostCustomers = (data) => {
  const customerCountByDate = data.reduce((acc, customer) => {
    const date = customer.datetime_field.split("T")[0];
    acc[date] = (acc[date] || 0) + 1;
    return acc;
  }, {});

  const [dayWithMostCustomers, maxCount] = Object.entries(
    customerCountByDate
  ).reduce((maxEntry, entry) => (entry[1] > maxEntry[1] ? entry : maxEntry));

  const dateObject = new Date(dayWithMostCustomers);

  return {
    day: getDayOfWeek(dateObject),
    date: formatDate(dateObject),
    totalCustomers: maxCount,
  };
};

const getDayOfWeek = (date) => {
  return date.toLocaleDateString("en-US", { weekday: "long" });
};

export const formatDate = (date) => {
  const day = date.getDate();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const suffix = getOrdinalSuffix(day);

  return formattedDate.replace(day.toString(), `${day}${suffix}`);
};

// Helper function to get the ordinal suffix
const getOrdinalSuffix = (day) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export function CustomInput({ value, onClick }) {
  return (
    <div className="input-date-parent">
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          value={value}
          onClick={onClick}
          readOnly
        />
        <div className="input-group-append">
          <span className="input-group-text">
            <FaCalendarAlt />
          </span>
        </div>
      </div>
    </div>
  );
}

export const capitalize = ([firstLetter, ...restOfWord]) =>
  firstLetter.toUpperCase() + restOfWord.join("");
