import { setLogin, setLogout } from "./LoginLogoutConstant";
export const actionCreators = {
  setLoginUser: (token) => async (dispatch) => {
    dispatch({ type: setLogin, payload: token });
  },

  setLogoutUser: () => async (dispatch) => {
    dispatch({ type: setLogout });
  },
};
