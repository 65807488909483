import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSuperLogin: false,
    sideToggle: false,
    superAuthData: {},
    store_owners: [],
    customers: []
}

export const isSuperSlice = createSlice({
  name: "SuperAdmin",
  initialState,
  reducers: {
    setSuperAuthData: (state, action) => {
      state.superAuthData = action.payload;
    },
    setIsSuperLogin: (state, action) => {
      state.isSuperLogin = action.payload;
    },
    setStoreOwners: (state, action) => {
      state.store_owners = action.payload;
    },
    setSideToggle: (state, action) => {
      state.sideToggle = action.payload;
    },
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },   
  },
});

export const {setSuperAuthData, setIsSuperLogin, setStoreOwners, setSideToggle, setCustomers} = isSuperSlice.actions;

export default isSuperSlice.reducer;