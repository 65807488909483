import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { useSelector } from "react-redux";
import "./GoogleReviews.css";

const GoogleReviews = () => {
  const placeId = useSelector((state) => state.salesToken.store_location);
  const [placeDetails, setPlaceDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!window.google) {
      console.error("Google Maps API not loaded");
      return;
    }

    if (!placeId) {
      console.error("Place ID not available");
      setLoading(false);
      return;
    }

    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );

    setLoading(true);
    service.getDetails(
      {
        placeId,
        fields: ["name", "rating", "user_ratings_total", "reviews"],
      },
      (place, status) => {
        if (status === "OK") {
          setPlaceDetails(place);
        } else {
          console.error("Failed to fetch place details:", status);
        }
        setLoading(false);
      }
    );
  }, [placeId]);

  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={`rating-star ${i <= rating ? "filled" : ""}`}
        />
      );
    }
    return stars;
  };

  return (
    <div className="google-reviews-container">
      {loading ? (
        <Spinner animation="border" role="status" className="loading-spinner">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : !placeDetails ? (
        <p>No store details available.</p>
      ) : (
        <>
          <h2 className="google-reviews-heading">{placeDetails.name}</h2>
          {placeDetails.reviews && (
            <div className="average-rating-section">
              <h4 className="average-rating">
                {placeDetails.rating.toFixed(1)}/5
              </h4>
              <div className="rating-icons">
                {renderRatingStars(Math.floor(placeDetails.rating))}
              </div>
              <p className="total-reviews m-3">
                {placeDetails.user_ratings_total} reviews
              </p>
            </div>
          )}
          <div className="reviews-list">
            {placeDetails.reviews && placeDetails.reviews.length > 0 ? (
              placeDetails.reviews.filter((review) => review.text).length >
              0 ? (
                placeDetails.reviews
                  .filter((review) => review.text)
                  .map((review) => (
                    <Card
                      key={review.time}
                      className="review-card mb-3"
                      style={{ width: "25rem" }}
                    >
                      <Card.Body>
                        <div className="review-header">
                          {review.profile_photo_url && (
                            <img
                              src={review.profile_photo_url}
                              alt={review.author_name}
                              className="review-author-img"
                            />
                          )}
                          <div className="review-author-info">
                            <Card.Text className="review-author mb-0">
                              {review.author_name}
                            </Card.Text>
                            <Card.Text className="review-time">
                              {review.relative_time_description}
                            </Card.Text>
                          </div>
                        </div>
                        <Card.Text className="review-text">
                          &quot;{review.text}&quot;
                        </Card.Text>
                        <div className="review-rating">
                          {renderRatingStars(review.rating)}{" "}
                          <span className="ml-3">{review.rating}/5 </span>
                        </div>
                      </Card.Body>
                    </Card>
                  ))
              ) : (
                <p>No Google reviews available yet.</p>
              )
            ) : (
              <p>No Google reviews available yet.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleReviews;
