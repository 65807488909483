import { adminLoginFlag, masterStoreLogin } from './StoreAdminConstant';

export const actionCreators = {
    masterStoreAdmin: (token) => async (dispatch, getState) => {
        dispatch({ type: masterStoreLogin, payload: token })
    },

    adminLoginFlag: (flag) => async (dispatch) => {
        dispatch({type: adminLoginFlag, payload: flag})
    }
}

