import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import axios from "axios";
import DashbaordHeader from "./DashbaordHeader";
import { Spinner, Card, Button } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import "./RemindersData.css";
import { MdDelete } from "react-icons/md";
import toast from "react-hot-toast";

const RemindersData = () => {
  const adminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [idList, setIdList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const fetchNotifications = async (signal) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseURL}/store_owner/notifications/`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      setMessages(response.data.reverse());
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchNotifications(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const handleCheck = (e, id) => {
    e.stopPropagation();
    setIdList((prevList) =>
      prevList.includes(id)
        ? prevList.filter((itemId) => itemId !== id)
        : [...prevList, id]
    );
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`${baseURL}/notifications/delete/`, {
        data: { notification_id: idList },
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });

      if (response.status === 200) {
        const newMessages = messages.filter((msg) => !idList.includes(msg.id));
        setMessages(newMessages);
        setIdList([]);
        toast.success("Reminders deleted successfully");
      }
    } catch (error) {
      toast.error("Something's wrong, please try again later");
      console.log(error);
    }
  };

  const handleSeen = async (id) => {
    const message = messages.find((msg) => msg.id === id);
    if (message?.storeowner_notification_seen) {
      return;
    }
    try {
      const response = await axios.post(
        `${baseURL}/reminders/${id}/seen/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );

      if (response.status === 200) {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === id ? { ...msg, storeowner_notification_seen: true } : msg
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setIdList([]);
    } else {
      setIdList(messages.map((msg) => msg.id));
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <Spinner animation="border" />
        </div>
      )}
      <section className="Dashboard-wrapper not-head">
        <DashbaordHeader />
        <div className="reminder-btn-wrapper">
          <Button variant="primary" onClick={handleSelectAll}>
            {selectAll ? "Deselect All" : "Select All"}
          </Button>
          <Button
            variant="danger"
            onClick={handleDelete}
            className="delete-btn"
            disabled={idList.length === 0}
          >
            <MdDelete /> Delete {idList.length > 0 && `(${idList.length})`}
          </Button>
        </div>
        <div className="cards-container">
          {messages && messages.length > 0 ? (
            messages.map((item, index) => (
              <Card
                className={`p-3 m-3 not-card ${
                  item.storeowner_notification_seen ? "seen" : "unseen"
                }`}
                onClick={() => handleSeen(item.id)}
                key={item.id}
                style={{ animationDelay: `${index * 0.2}s` }}
              >
                <div className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleCheck(e, item.id)}
                    checked={idList.includes(item.id)}
                  />
                  <img
                    className="reminder-card-logo"
                    src="/img/reminder.png"
                    alt="Reminder"
                  />
                  <div className="notification-content">
                    <div className="notification-description">
                      {item.customer.description}
                    </div>
                    <div className="notification-datetime">
                      {moment(item.reminder_datetime).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </div>
                    <div className="notification-customer">
                      <strong>Name:</strong> {item.customer.name}
                    </div>
                    <div className="notification-customer">
                      <strong>Phone:</strong> {item.customer.phone_number}
                    </div>
                    <div className="notification-created">
                      <strong>Created At:</strong>{" "}
                      {moment(item.reminder_created_at).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </div>
                    <div className="notification-seen">
                      <strong>Salesperson Seen:</strong>{" "}
                      {item.salesperson_notification_seen ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <div className="empty-state">
              <img src="/img/page.png" alt="No reminders" />
              <div>No reminders set yet</div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default RemindersData;
