import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Form } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const inputStyle = {
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  marginBottom: "1rem",
  width: "100%",
  boxSizing: "border-box",
  padding: "0.375rem 0.75rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

const saveButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
};

export default function SalesResetModal(props) {
  const [newPass, setNewPass] = React.useState("");
  const [error, setError] = useState("");
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );

  const handleInput = (e) => {
    setNewPass(e.target.value);
  };

  const handleSubmit = () => {
    if (newPass === "") {
        setError("New Password is required");
        return;
      }

    axios({
      url: `${baseURL}/salesperson/reset-password/`,
      data: {
        email: props.email,
        new_password: newPass
      },
      headers: {
        Authorization: `Bearer ${getStoreAdminToken}`,
      },
      method: "post",
    }).then((result) => {
      toast.success("Password Reset successfully!")
      props.close();
      props.fetch();
    }).catch((error) => {
      console.error(error);
      toast.error("Something's wrong, please try again later")
    })
  };

  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reset Password
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>       
            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={props.email || ""}
                onChange={(e) => handleInput(e)}
                style={inputStyle}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="new_password" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="text"
                name="new_password"
                value={newPass || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter New Password"
                style={inputStyle}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Form.Group>
            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={handleSubmit}
              >
                Save Changes
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
