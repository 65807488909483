import React, { useEffect, useState } from "react";
import { DateRangePicker } from "rsuite";
import { startOfDay, endOfDay, subDays } from "date-fns";
import { isBefore, isAfter } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const CustomerCalendar = ({ onDateRangeChange = () => {}, startDate = "" }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  console.log("selectedDate", selectedDate);
  const handleShortcut = (range) => {
    const today = new Date();
    let startDate, endDate;
    switch (range) {
      case "today":
        startDate = startOfDay(today);
        endDate = endOfDay(today);
        break;
      case "yesterday":
        startDate = startOfDay(subDays(today, 1));
        endDate = endOfDay(subDays(today, 1));
        break;
      case "last7days":
        startDate = startOfDay(subDays(today, 6));
        endDate = endOfDay(today);
        break;
      case "last30days":
        startDate = startOfDay(subDays(today, 30));
        endDate = endOfDay(today);
        break;
      default:
        startDate = null;
        endDate = null;
    }
    setSelectedDate([startDate, endDate]);
    onDateRangeChange([startDate, endDate]);
  };

  const handleDate = (date) => {
    setSelectedDate(date);
    onDateRangeChange(date);
  };

  return (
    <div className="customer-calendar">
      <label>
        <DateRangePicker
          showOneCalendar={true}
          value={selectedDate}
          onChange={handleDate}
          size="md"
          appearance="subtle"
          format="dd-MMM-yyyy"
          shouldDisableDate={(date) =>
            isAfter(date, new Date()) ||
            (startDate && isBefore(date, startOfDay(new Date(startDate))))
          }
          shortcuts={[
            {
              label: "Today",
              onClick: () => handleShortcut("today"),
            },
            {
              label: "Yesterday",
              onClick: () => handleShortcut("yesterday"),
            },
            {
              label: "Last 30 Days",
              onClick: () => handleShortcut("last30days"),
            },
            {
              label: "Last 7 Days",
              onClick: () => handleShortcut("last7days"),
            },
          ]}
          placeholder="Select Date Range"
        />
      </label>
    </div>
  );
};

export default CustomerCalendar;

// useEffect(() => {
//   if (startDate) {
//     const startDate = startOfDay(subDays(new Date(), 6));
//     const endDate = endOfDay(new Date());
//     setSelectedDate([startDate, endDate]);
//     onDateRangeChange([startDate, endDate]);
//   }
// }, [startDate]);
