import React from "react";
import DashbaordHeader from "./DashbaordHeader";
import DashboardCard from "./DashboardCard";
import DashboardChart from "./DashboardChart";
import Example from "./Example";
import ProgressBar from "./ProgressBar";
import { useSelector } from "react-redux";
import "rsuite/dist/rsuite.css";
import GoogleReviews from "./GoogleReviews";

const DashboardMainContent = () => {
  const getToggleSidebar = useSelector((state) => state.toggle.sidebarToggle);

  return (
    <>
      <section
        className={`Dashboard-wrapper ${
          getToggleSidebar ? "" : "Dashbaord-wrapper-margin-left"
        }`}
      >
        <DashbaordHeader />
        <DashboardCard />
        <div className="h-auto pie-chart-wrapper">
          <div className="top-section">
            <div
              className="title"
              style={{ textAlign: "left", margin: "0 0 0.8rem 0" }}
            >
              <h4 className="mb-0">Customer Count</h4>
              <p style={{ fontSize: "15px" }}>Track your Monthly Customers</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="cart-wrapper">
                <DashboardChart />
              </div>
            </div>
          </div>
        </div>
        <div className="h-auto pie-chart-wrapper">
          <h4 className="mb-0">Sales Performance</h4>
          <p style={{ fontSize: "15px" }}>
            Track salesperson performance with shoppers and visitors
          </p>
          <div className="row">
            <div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
              <div className="chart-inner">
                <ProgressBar />
              </div>
            </div>
          </div>
        </div>
        <div className="pie-chart-wrapper">
          <div className="d-flex justify-content-center align-items-center">
            <Example />
          </div>
        </div>
        <div className="h-auto pie-chart-wrapper">
          <GoogleReviews />
        </div>
      </section>
    </>
  );
};

export default DashboardMainContent;
