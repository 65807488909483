import React from "react";
import { featuresData } from "../../utils/helperObj";

const FeaturesSection = () => {
  return (
    <div className="main-features-container">
      <h2>Features</h2>
      <h4>Our all in one Retail Assistant has got it all</h4>
      <div className="main-feature-card-wrapper">
        {featuresData.map((feature, index) => (
          <div key={index} className="main-feature-card">
            <img
              src={feature.img}
              className="main-feature-card-img"
              alt={`Feature ${index + 1}`}
            />
            <div className="main-feature-card-content">{feature.content}</div>
          </div>
        ))}
      </div>
      <div className="main-feature-footer">
        Join the journey of more than 1000+ Businesses who have discovered
        modern way of connecting with their customers
      </div>
    </div>
  );
};

export { FeaturesSection };
