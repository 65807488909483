import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaStore } from "react-icons/fa";
import "./Super.css";

const SuperSidebar = () => {
  const { sideToggle } = useSelector((state) => state?.superAdminData);
  const navigate = useNavigate();

  const handleNavigation = (e, value) => {
    e.preventDefault();
    navigate(value);
  };

  return (
    <>
      <aside
        className={`super-left-sidebar ${sideToggle ? "active" : "unactive"}`}
      >
        <div>
          <img src="../img/logo.jpeg" alt="logo" className="img-fluid" />
          <menu>
            <ul className="super-list-unstyled">
              <li className="active">
                <div
                  onClick={(e) => handleNavigation(e, "/super/dashboard")}
                  style={{ cursor: "pointer" }}
                >
                  <i
                    className="fa-solid fa-clapperboard"
                    style={{ color: "#4ba3dd" }}
                  ></i>
                  &nbsp;&nbsp; <span className="list">Dashboard</span>
                </div>
              </li>
              <li>
                <div
                  onClick={(e) => handleNavigation(e, "/super/storeowners")}
                  style={{ cursor: "pointer" }}
                >
                  <FaStore style={{ color: "#4ba3dd" }} /> &nbsp;&nbsp;
                  <span className="list">Store Owners</span>
                </div>
              </li>
            </ul>
          </menu>
        </div>
      </aside>
    </>
  );
};

export { SuperSidebar };
