import React from "react";
import { featuresArray } from "../../utils/helperObj";

const KeyFeatures = () => {
  return (
    <div className="key-features-container">
      <h2>
        Turn your customer into Marketing Engine
      </h2>
      <div className="features-card-wrapper">
        {featuresArray && featuresArray.map((feature, index) => {   
        return <div key={index} className="features-card">
          <img
            src={feature.img}
            className="features-card-img"
          />
          <div className="features-card-content">
            {feature.content}
          </div>
        </div>
        })}
      </div>
    </div>
  );
};

export { KeyFeatures };
