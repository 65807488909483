import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
// import toast, { Toaster } from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
import { baseURL } from "../utils/helperObj";

const COLORS = ["#125f9a", "#65129a"];
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Example = () => {
  // const navigate = useNavigate();
  const [shopper, setShopper] = useState([]);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );

  const handleShopper = () => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/sales-representative-percentage-visit-count/`,
        data: {},
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          setShopper([
            { name: "Shoppers", value: result.data.total_shoppers_percentage },
            { name: "Visitors", value: result.data.total_visitors_percentage },
          ]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  useEffect(() => {
    handleShopper();
  }, []);

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className="pie-chart-parent"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h4 className="mb-0" style={{ textAlign: "left" }}>
          Shoppers and Visitors
        </h4>
        <p className="mb-2" style={{ fontSize: "15px" }}>
          Track the total number of shoppers and visitors to your store
        </p>
        <div className="d-flex justify-content-center align-items-center w-100">
          <div
            className="color-pilot-name-customer m-1"
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <span
              className="dot"
              style={{ display: "flex", justifyContent: "flex-start" }}
            ></span>
            &nbsp;
            <p
              style={{
                fontSize: "15px",
                marginBottom: "0px",
                // color: "#a6a6a6",
                fontWeight: "600",
              }}
              onClick={handleShopper}
            >
              Shoppers
            </p>
          </div>
          <div
            className="color-pilot-name-visitor m-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span className="dot"></span>
            &nbsp;
            <p
              style={{
                fontSize: "15px",
                marginBottom: "0px",
                // color: "#a6a6a6",
                fontWeight: "600",
              }}
            >
              Visitors
            </p>
          </div>
        </div>
        <PieChart>
          <Pie
            data={shopper}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={110}
            fill="#8884d8"
            dataKey="value"
          >
            {shopper.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
        {/* </div> */}
      </ResponsiveContainer>
    </>
  );
};

export default Example;
