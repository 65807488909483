import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Sidebarr from "../Component/Sidebarr";
import DashboardCustomerData from "../Component/DashboardCustomerData";
import RemindersData from "../Component/RemindersData";

const Reminders = () => {
  const getToggleSidebar = useSelector((state) => state.toggle.sidebarToggle);

  const dashboardWrapperClassName = useMemo(() => 
    `Dashboard-wrapper ${getToggleSidebar ? "" : "Dashbaord-wrapper-margin-left"}`, 
    [getToggleSidebar]
  );

  return (
    <main>
      <section className={dashboardWrapperClassName}>
        <Sidebarr />
        <RemindersData />
      </section>
    </main>
  );
};

export default React.memo(Reminders);
