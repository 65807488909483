import React from "react";
import { useSelector } from "react-redux";
import "./Super.css";

const SuperCard = () => {
  const { store_owners } = useSelector((state) => state?.superAdminData);

  return (
    <>
    <div className="super-dashbard-inner-card">
      <div className="row" id="cart-header-three-box">
        <div className="col-md-6 col-lg-3 m-2">
          <div className="super-card-inner">
            <div className="top-content">
              <h2>Total Store Owners</h2>
            </div>
            <div className="super-middle-text">
              <span>{store_owners.length}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 m-2">
          <div className="super-card-inner">
            <div className="top-content">
              <h2>Total Customers</h2>
            </div>
            <div className="super-middle-text">
              <span>{store_owners.length}</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 m-2">
          <div className="super-card-inner">
            <div className="top-content">
              <h2>Total Customers</h2>
            </div>
            <div className="super-middle-text">
              <span>{store_owners.length}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export { SuperCard };
