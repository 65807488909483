import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../utils/helperObj";

const DashboardCard = ({ cardData }) => {
  const navigate = useNavigate();
  const [footfall, setFootfall] = useState(0);
  const [shopper, setShopper] = useState(0);
  const [visitor, setVisitor] = useState(0);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );

  const handleTotalFootfall = () => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/store-owner/customer-count/`,
        data: {},
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          setFootfall(result.data.customer_count);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Session expired. Please login again.", {
              duration: 2000,
            });
            setTimeout(() => navigate("/adminlogin"), 1000);
          } else {
            toast.error("Something's wrong, please try again later");
          }
        });
    }
  };

  const handleShopper = () => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/sales-representative-percentage-visit-count/`,
        data: {},
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      })
        .then((result) => {
          setVisitor(result.data.total_visitors_percentage);
          setShopper(result.data.total_shoppers_percentage);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            toast.error("Session expired. Please login again.", {
              duration: 2000,
            });
            setTimeout(() => navigate("/adminlogin"), 1000);
          } else {
            toast.error("Something's wrong, please try again later");
          }
        });
    }
  };

  useEffect(() => {
    if (cardData) {
      setFootfall(cardData.total_customers);
      setShopper(cardData.shoppers_percentage);
      setVisitor(cardData.visitors_percentage);
    } else {
      handleTotalFootfall();
      handleShopper();
    }
  }, [cardData, getStoreAdminToken]);

  return (
    <>
      <div className="dashbard-inner-card">
        <div className="row" id="cart-header-three-box">
          <div className="col-md-6 col-lg-3">
            <div className="card-inner">
              <div className="top-content">
                <h2>Total Customers</h2>
              </div>
              <div className="middle-text">
                <span>{footfall}</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card-inner">
              <div className="top-content">
                <h2>Shoppers</h2>
              </div>
              <div className="middle-text">
                <span>{shopper?.toFixed(0)}%</span>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card-inner">
              <div className="top-content">
                <h2>Visitors</h2>
              </div>
              <div className="middle-text">
                <span>{visitor?.toFixed(0)}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCard;
