import {
  salesToken,
  salesNameCustomer,
  storeLocation,
} from "./StoreAdminConstant";
export const actionCreators = {
  salesToken: (token) => async (dispatch, getState) => {
    dispatch({ type: salesToken, payload: token });
  },

  setCustomerName: (name) => async (dispatch, getState) => {
    dispatch({ type: salesNameCustomer, payload: name });
  },

  setStoreLocation: (location) => async (dispatch) => {
    dispatch({ type: storeLocation, payload: location });
  },
};
