import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { capitalize } from "../../utils/helperFunctions";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

function formatDatetime(datetime) {
  const date = new Date(datetime);
//   const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;
  const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  return formattedDate;
}

function CustomerModal(props) {
  const { record } = props;

  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Customer Record
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className="table-inner-content table-responsive">
              <table
                id="res-table-layout-admin"
                className="table text-center table-bordered table-hover container"
              >
                <thead className="thead-light">
                  <tr>
                    <th>S. No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Visit Type</th>
                    <th>Sales Rep</th>
                    <th>Feedback</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {record &&
                    record.map((val, index) => (
                      <tr key={val.id}>
                        <td>{index + 1}</td>
                        <td>{capitalize(val?.name)}</td>
                        <td>{val?.email}</td>
                        <td>{val?.phone_number}</td>
                        <td>{capitalize(val?.visit_type)}</td>
                        <td>{val?.salesperson_name}</td>
                        <td>{val?.description}</td>
                        <td>{formatDatetime(val?.datetime_field)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}

export { CustomerModal };
