import React, { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { actionCreators } from "../Store/SidebarComponent/SidebarAction";
import "rsuite/dist/rsuite.css";
import { actionCreators as LoginLogoutActionCreators } from "../Store/LoginLogout/LoginLogoutAction";
import { actionCreators as AdminLoginFlagAction } from "../Store/StoreAdminAuth/StoreAdminAction";
import { CiLogout } from "react-icons/ci";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { RiLockPasswordFill } from "react-icons/ri";
import ChangePasswordModal from "./ChangePasswordModal";
import { IoMdNotifications } from "react-icons/io";
import { baseURL } from "../utils/helperObj";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

const DashboardHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin?.access
  );
  const salesPersonName = useSelector((state) => state.salesToken.customerName);
  const getToggleSidebar = useSelector((state) => state.toggle.sidebarToggle);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleData = () => {
    dispatch(actionCreators.SidebarToggle(!getToggleSidebar));
  };

  const handleLogout = () => {
    dispatch(LoginLogoutActionCreators.setLogoutUser());
    dispatch(AdminLoginFlagAction.adminLoginFlag(false));
    dispatch(AdminLoginFlagAction.masterStoreAdmin({}));
    navigate("/adminlogin");
  };

  const handleChangePassword = () => {
    setShowChangeModal(true);
  };

  const fetchNotifications = async (signal) => {
    try {
      const response = await axios.get(
        `${baseURL}/store_owner/notifications/`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      // Filter notifications that are not seen
      const unseenNotifications = response.data.filter(
        (notification) => !notification.storeowner_notification_seen
      );
      setNotifications(unseenNotifications);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchNotifications(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {showChangeModal && (
        <ChangePasswordModal
          showChangeModal={showChangeModal}
          close={() => setShowChangeModal(false)}
        />
      )}
      <header>
        <div className="top-bar">
          <div
            id="top-left"
            className="top-left d-flex align-items-center justify-content-center"
          >
            <div className="mr-2 cursor-pointer" onClick={(e) => handleData(e)}>
              <BsFillMenuButtonWideFill />
            </div>
            {window.location.pathname === "/admin" && (
              <>
                <div className="fs-2 fw-bold">Admin Dashboard</div>
              </>
            )}
            {window.location.pathname === "/sales-data" && (
              <div className="fs-2 fw-bold">Sales Representative</div>
            )}
            {window.location.pathname === "/customer-data" && (
              <div className="fs-2 fw-bold">Customers</div>
            )}
            {window.location.pathname === "/reminders" && (
              <div className="fs-2 fw-bold">Reminders</div>
            )}
          </div>
          <div className="top-right" style={{ textAlign: "center" }}>
            <div className="avatar-wrap">
              <FaRegUser />
              &nbsp; &nbsp;
              <span className="user-name">Hello {salesPersonName}</span>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                variant="light"
                id="notification-dropdown-toggle"
              >
                <div className="not-counter">{notifications.length}</div>
                <IoMdNotifications style={{ fontSize: "1.1rem" }} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {notifications.length > 0 ? (
                  // notifications.map((notification, index) => (
                    <Dropdown.Item>
                      <div>{notifications.length} new reminders created!</div>
                      <Link to={`/reminders`}>
                        See details
                      </Link>
                    </Dropdown.Item>
                  // )
                // )
                ) : (
                  <Dropdown.Item>No new notifications</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <button
              className="btn-logout-admindashboard"
              onClick={(e) => handleChangePassword(e)}
            >
              <RiLockPasswordFill />
            </button>
            <button
              className="btn-logout-admindashboard"
              onClick={(e) => handleLogout(e)}
            >
              <CiLogout style={{ fontWeight: "bold" }} />
              <span className="logout-text-mobile m-1">Logout</span>
            </button>
          </div>
        </div>
      </header>
    </>
  );
};

export default DashboardHeader;
