import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Form } from "react-bootstrap";
import { baseURL } from "../utils/helperObj";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  padding: "2rem",
};

const inputStyle = {
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  marginBottom: "1rem",
  width: "100%",
  boxSizing: "border-box",
  padding: "0.375rem 0.75rem",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#dc3545",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#c82333",
  },
};

const saveButtonStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
};

export default function StoreEditModal(props) {
  const [data, setData] = React.useState(props?.data);
  const { superAuthData } = useSelector((state) => state?.superAdminData);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number" && !/^\d*$/.test(value)) return;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await axios({
      url: `${baseURL}/master-admin/store-owners/`,
      data: {
        id: data.id,
        email: data.email,
        store_name: data.store_name,
        address: data.address,
        phone_number: data.phone_number,
        registration_date: data.registration_date,
        google_place_id: data.google_place_id,
        google_review_link: data.google_review_link,
      },
      headers: {
        Authorization: `Bearer ${superAuthData.tokens.access}`,
      },
      method: "put",
    })
      .then(() => {
        toast.success("Details updated successfully!");
        props.close();
        props.fetch();
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something's wrong, please try again later");
      });
  };

  return (
    <div>
      <Modal
        open
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit StoreOwner
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
            <Form.Group controlId="email" className="mt-1">
              <Form.Label className="m-0">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                className="m-0"
                value={data.email || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Email"
                style={inputStyle}
                readOnly
              />
            </Form.Group>
            <Form.Group controlId="registration_date" className="mt-1">
              <Form.Label className="m-0">Registration Date</Form.Label>
              <Form.Control
                type="text"
                className="m-0"
                name="registration_date"
                value={data.registration_date || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Reg. Date"
                readOnly
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="store_name" className="mt-1">
              <Form.Label className="m-0">Store Name</Form.Label>
              <Form.Control
                type="text"
                className="m-0"
                name="store_name"
                value={data.store_name || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Store Name"
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="google_place_id" className="mt-1">
              <Form.Label className="m-0">Google Place ID</Form.Label>
              <Form.Control
                type="text"
                className="m-0"
                name="google_place_id"
                value={data.google_place_id || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Google Place id"
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="google_review_link" className="mt-1">
              <Form.Label className="m-0">Google Review Link</Form.Label>
              <Form.Control
                type="text"
                className="m-0"
                name="google_review_link"
                value={data.google_review_link || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Google review link"
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="address" className="mt-1">
              <Form.Label className="m-0">Address</Form.Label>
              <Form.Control
                type="text"
                className="m-0"
                name="address"
                value={data.address || ""}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Address"
                style={inputStyle}
              />
            </Form.Group>
            <Form.Group controlId="phone_number" className="mt-1">
              <Form.Label className="m-0">Phone Number</Form.Label>
              <Form.Control
                type="tel"
                className="m-0"
                name="phone_number"
                value={data.phone_number || ""}
                maxLength={10}
                onChange={(e) => handleInput(e)}
                placeholder="Enter Phone Number"
                style={inputStyle}
              />
            </Form.Group>

            <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={props.close}
              >
                Close
              </Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
