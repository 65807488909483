import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import { SuperHeader } from "../../Component/SuperAdminComp/SuperHeader";
import { baseURL } from "../../utils/helperObj";
import { SuperCard } from "../../Component/SuperAdminComp/SuperCard";
import { setStoreOwners } from "../../Store/ToolkitReducers/SuperAdminReducer";
import { SuperSidebar } from "../../Component/SuperAdminComp/SuperSidebar";

const SuperAdminLayout = () => {
  const { isSuperLogin, superAuthData, sideToggle } = useSelector(
    (state) => state?.superAdminData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const abortController = new AbortController();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/master-admin/store-owners/`,
          {
            signal: abortController.signal,
            headers: {
              Authorization: `Bearer ${superAuthData?.tokens?.access}`,
            },
          }
        );
        dispatch(setStoreOwners(response.data));
      } catch (error) {
        console.error("Error fetching notifications:", error);
        if (error?.response?.status === 401) {
          toast.error("Session expired. Please login again.", {
            duration: 2000,
          });
          setTimeout(() => navigate("/superlogin"), 1000);
        }
        if (error.name === "CanceledError") {
          console.log("API request was canceled", error);
          return;
        }
        console.error("fetch error", error);
        toast.error("Unable to fetch details right now, try again later");
      }
    };
    fetchData();
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <>
      {isSuperLogin && (
        <>
          <div>
            {sideToggle && <SuperSidebar />}
            <section
              className={`Dashboard-wrapper ${
                sideToggle ? "Dashbaord-wrapper-margin-left" : ""
              }`}
            >
              <SuperHeader />
              <SuperCard />
              <Outlet />
            </section>
          </div>
        </>
      )}
    </>
  );
};

export { SuperAdminLayout };
