import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import "./DashboardMainSalesCreate.css";
import DashbaordHeader from "./DashbaordHeader";
import DashboardCard from "./DashboardCard";
import { actionCreators } from "../Store/SalesAuthToken/StoreAdminAction";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import Button from "@mui/material/Button";
import { baseURL } from "../utils/helperObj";

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: "1rem",
};

const closeButtonStyle = {
  backgroundColor: "#6c757d", // grayish background color
  color: "#fff", // white text color
  "&:hover": {
    backgroundColor: "#5a6268", // darker grayish color on hover
  },
};
const saveButtonStyle = {
  background: "linear-gradient(180deg, #6e46c9, #4ba1dc)",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#0056b3",
  },
};

const DashboardMainSalesCreate = () => {
  const [disable, setDiasable] = useState(false);
  const navigate = useNavigate();
  const [apiResData, setApiResDataa] = useState([]); // sales register
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const getStoreAdminToken = useSelector(
    (state) => state?.storeAdminLogin?.storeAdmin.access
  );

  const [salesRegister, setSalesRegister] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleInput = (e) => {
    e.preventDefault();
    setSalesRegister({
      ...salesRegister,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    setDiasable(true);

    let errorData = {};
    if (salesRegister.name === "") {
      errorData.name = "Please enter a name";
    } else {
      errorData.name = "";
    }
    if (
      !String(salesRegister.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      errorData.email = "Please enter a valid email";
    } else {
      errorData.email = "";
    }
    if (salesRegister.password === "") {
      errorData.password = "Please enter a password";
    } else {
      errorData.password = "";
    }

    if (
      errorData.name !== "" ||
      errorData.email !== "" ||
      errorData.password !== ""
    ) {
      setError(errorData);
      setDiasable(false); // Reset the disable state
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/salesperson/create/`,
        {
          name: salesRegister.name,
          email: salesRegister.email,
          password: salesRegister.password,
        },
        {
          headers: {
            Authorization: `Bearer ${getStoreAdminToken}`,
          },
        }
      );

      if (response.data.error) {
        // Display an error message for existing user or any other error
        toast.error(response.data.error, {
          position: "top-right",
          duration: 2000,
          id: "toast-error",
        });
      } else {
        // Process the successful registration
        const token = response.data.tokens;
        setApiResDataa(response.data);
        dispatch(actionCreators.salesToken(token));
        toast.success("Successfully registered!", {
          position: "top-right",
          id: "toast-success",
        });
        setTimeout(() => {
          toast.remove("toast-success");
          navigate("/sales-data");
          setDiasable(false);
        }, 500);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error, {
          position: "top-right",
          duration: 2000,
          id: "toast-error",
        });
        
      } else {
        toast.error("Unable to Register. Please try again!.", {
          position: "top-right",
          duration: 5000,
          id: "toast-error",
        });
      }
      setDiasable(false);
      console.error("error", error);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    // e.preventDefault();
    navigate("/sales-data")
  }

  useEffect(() => {
    if (getStoreAdminToken) {
      axios({
        url: `${baseURL}/store-owner/customer-count/`,
        data: {},
        headers: {
          Authorization: `Bearer ${getStoreAdminToken}`,
        },
        method: "get",
      }).catch((error) => {
        console.error(error);
      });
    }
  }, []);
  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <Spinner />
        </div>
      )}
      <section
        className={`Dashboard-wrapper`}
        id="Dashbaord-wrapper-margin-left"
      >
        <DashbaordHeader />
        <DashboardCard />
        <div className="table-data-wrapper">
          <div className="table-inner-content table-responsive">
            <form className="sales-register-create">
              <h5 className="text-center">Sales Rep Registration</h5>
              <input
                type="text"
                id="name"
                name="name"
                value={salesRegister.name}
                onChange={(e) => handleInput(e)}
                placeholder="Name"
              />
              {error.name && (
                <p style={{ color: "red", fontSize: "14px" }}>{error.name}</p>
              )}
              <input
                type="text"
                id="email"
                name="email"
                value={salesRegister.email}
                onChange={(e) => handleInput(e)}
                placeholder="Email"
              />
              {error.email && (
                <p style={{ color: "red", fontSize: "14px" }}>{error.email}</p>
              )}
              <div className="password-input-wrapper">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  value={salesRegister.password}
                  onChange={(e) => handleInput(e)}
                  placeholder="Password"
                />
                <FontAwesomeIcon
                  style={{ marginLeft: "-20px", cursor: "pointer" }}
                  icon={showPassword ? faEye : faEyeSlash}
                  className={`eye-icon ${showPassword ? "open" : "closed"}`}
                  onClick={togglePasswordVisibility}
                />
              </div>
              {error.password && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {error.password}
                </p>
              )}
              <br />
              <div style={buttonContainerStyle}>
              <Button
                variant="contained"
                style={closeButtonStyle}
                onClick={handleClose}
              >Close</Button>
              <Button
                variant="contained"
                style={saveButtonStyle}
                onClick={(e) => handleSubmit(e)}
              >
                Register
              </Button>
            </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardMainSalesCreate;
