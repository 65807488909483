import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { FaDownload, FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { IoToggle, IoToggleOutline } from "react-icons/io5";
import { IoMdAddCircleOutline, IoMdArrowBack } from "react-icons/io";
import { baseURL } from "../../utils/helperObj";
import {
  setCustomers,
  setStoreOwners,
} from "../../Store/ToolkitReducers/SuperAdminReducer";
import "../../Component/SuperAdminComp/Super.css";
import SalesDeleteModal from "../../Component/SalesDeleteModal";
import StoreEditModal from "../../Component/StoreEditModal";
import { Button, Spinner } from "react-bootstrap";
import StoreAddModal from "../../Component/StoreAddModal";
import { handleExcel } from "../../utils/helperFunctions";

const SuperStoreOwners = () => {
  const { store_owners, superAuthData, customers } = useSelector(
    (state) => state?.superAdminData
  );
  const [editData, setEditData] = useState();
  const [delData, setDelData] = useState();
  const [showCustomers, setShowCustomers] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [salesGetData, setSalesGetData] = useState(store_owners || []);
  const [filteredData, setFilteredData] = useState(customers || []);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [modals, setModals] = useState({
    showModal: false,
    showModalDel: false,
    showModalReset: false,
    addModal: false,
  });

  const fetchData = async (signal) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${baseURL}/master-admin/store-owners/`,
        {
          signal,
          headers: {
            Authorization: `Bearer ${superAuthData?.tokens?.access}`,
          },
        }
      );
      setSalesGetData(response.data);
      dispatch(setStoreOwners(response.data));
    } catch (error) {
      console.error("Error fetching notifications:", error);
      if (error.name === "CanceledError") {
        console.log("API request was canceled", error);
        return;
      }
      console.error("fetch error", error);
      toast.error("Unable to fetch details right now, try again later");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    fetchData(abortController.signal);
    return () => {
      abortController.abort();
    };
  }, []);

  const handleCustomerDataBySales = (email) => {
    setIsLoading(true);
    axios({
      url: `${baseURL}/master-admin/customers-by-store-owner/`,
      data: {
        email: email,
      },
      headers: {
        Authorization: `Bearer ${superAuthData?.tokens?.access}`,
      },
      method: "post",
    })
      .then((result) => {
        if (result.data.length > 0) {
          dispatch(setCustomers(result.data));
          setFilteredData(result.data.slice(0, 10));
          setNumberOfPages(Math.ceil(result.data.length / 10));
          setShowCustomers(true);
        } else {
          toast.error("No customers for this store owner!");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          toast.error("Session expired. Please login again.", {
            duration: 2000,
          });
          setTimeout(() => navigate("/superlogin"), 2500);
        } else {
          toast.error("Something's wrong, please try again later");
        }
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    const newData = customers.slice(selectedPage * 10, (selectedPage + 1) * 10);
    setFilteredData(newData);
  };

  const handleDelClick = (e, value) => {
    setModals({ ...modals, showModalDel: true });
    setDelData(value);
  };

  const handleStoreEdit = (data) => {
    setModals({ ...modals, showModal: true });
    setEditData(data);
  };

  const handleToggleActivation = async (id, isActive) => {
    setIsLoading(true);
    const val = isActive === true ? "activate" : "deactivate";
    await axios
      .patch(
        `${baseURL}/master-admin/store-owners/`,
        {
          id,
          action: `${val}`,
        },
        {
          headers: { Authorization: `Bearer ${superAuthData?.tokens?.access}` },
        }
      )
      .then((response) => {
        const updatedData = salesGetData.map((salesperson) => {
          if (salesperson.id === id) {
            return { ...salesperson, is_active: isActive };
          }
          return salesperson;
        });
        setSalesGetData(updatedData);
        toast.success(
          `Store owner ${isActive ? "activated" : "deactivated"} successfully!`
        );
      })
      .catch((error) => {
        console.error("Error toggling activation status:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSuperExcel = () => {
    handleExcel(customers);
  };

  const handleStoreBack = () => {
    setShowCustomers(false);
    dispatch(setCustomers([]));
    setNumberOfPages(1);
    setFilteredData([]);
  };

  return (
    <>
      {isLoading && (
        <div className="loader-overlay">
          <Spinner />{" "}
        </div>
      )}
      {modals && modals?.showModal && (
        <StoreEditModal
          showModal={modals.showModal}
          data={editData}
          close={() => setModals({ ...modals, showModal: false })}
          fetch={fetchData}
        />
      )}
      {modals && modals?.addModal && (
        <StoreAddModal
          showModal={modals.addModal}
          close={() => setModals({ ...modals, addModal: false })}
          fetch={fetchData}
        />
      )}
      {modals && modals?.showModalDel && (
        <SalesDeleteModal
          showModal={modals?.showModalDel}
          data={delData}
          close={() => setModals({ ...modals, showModalDel: false })}
          fetch={fetchData}
        />
      )}
      <div className="buttom-parent-div-sales">
        <button
          className="button-30-add-sales-admin"
          onClick={() => setModals({ ...modals, addModal: true })}
          style={{ textAlign: "right", borderRadius: "1rem" }}
        >
          <IoMdAddCircleOutline
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              margin: "0 0.5rem 0 0",
            }}
          />
          <span>Add Store Owner</span>
        </button>
      </div>
      {showCustomers ? (
        <div className="table-data-wrapper">
          <div className="table-heading-wrapper">
            <div
              style={{
                textAlign: "left",
                paddingLeft: "10px",
                margin: "1rem 1.2rem",
                fontWeight: "bold",
                fontSize: "1.6rem",
              }}
            >
              Customer Data
              <Button
                variant="light"
                className="ml-4"
                onClick={handleStoreBack}
              >
                <IoMdArrowBack />
              </Button>
            </div>
            <Button
              variant="light"
              className="excel-btn"
              onClick={handleSuperExcel}
            >
              <FaDownload />
            </Button>
          </div>
          <div className="table-inner-content table-responsive">
            <table
              id="res-table-layout-admin"
              className="table text-center table-bordered table-hover container"
            >
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Visit Type</th>
                  <th>Sales Representative</th>
                  <th>Feedback</th>
                </tr>
              </thead>
              <tbody>
                {filteredData &&
                  filteredData?.map((val, index) => (
                    <tr key={val.id}>
                      <td>{index + 1}</td>
                      <td>{val?.name}</td>
                      <td>{val?.email}</td>
                      <td>{val?.phone_number}</td>
                      <td>{val?.visit_type}</td>
                      <td>{val?.salesperson_name}</td>
                      <td>{val?.description}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <i style={{ color: "#6e46c9" }} className="fas fa-angle-left"></i>
            }
            nextLabel={
              <i
                style={{ color: "#6e46c9" }}
                className="fas fa-angle-right"
              ></i>
            }
            breakLabel={"..."}
            pageCount={numberOfPages}
            marginPagesDisplayed={3}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      ) : (
        <div className="table-data-wrapper">
          <h5
            style={{
              textAlign: "left",
              paddingLeft: "10px",
              padding: "10px",
              margin: "1rem",
              fontSize: "1.6rem",
            }}
          >
            Store Owner
          </h5>
          <div className="table-inner-content table-responsive">
            <table
              className="table text-center table-bordered table-hover container"
              id="res-table-layout-admin"
            >
              <thead>
                <tr>
                  <th scope="col">S No.</th>
                  <th scope="col">Store Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone No.</th>
                  <th scope="col">Registration Date</th>
                  <th scope="col">Address</th>
                  <th scope="col">Status</th>
                  <th scope="col">Edit Details</th>
                  <th scope="col">Delete Record</th>
                </tr>
              </thead>
              <tbody>
                {salesGetData.map((val, index) => (
                  <tr key={val.id}>
                    <th scope="row">
                      {" "}
                      <span>{index + 1}</span>
                    </th>
                    <td
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => handleCustomerDataBySales(val.email)}
                    >
                      {val.store_name}
                    </td>
                    <td>{val.email}</td>
                    <td>{val.phone_number}</td>
                    <td>{val.registration_date}</td>
                    <td>{val.address}</td>
                    <td
                      onClick={() =>
                        handleToggleActivation(val.id, !val.is_active)
                      }
                    >
                      {val.is_active ? (
                        <IoToggle
                          style={{
                            fontSize: "20px",
                            backgroundColor: "none",
                            color: "green",
                          }}
                        />
                      ) : (
                        <IoToggleOutline
                          style={{
                            fontSize: "20px",
                            backgroundColor: "none",
                            color: "gray",
                          }}
                        />
                      )}
                    </td>
                    <td onClick={(e) => handleStoreEdit(val)}>
                      <FaEdit
                        style={{
                          fontSize: "1rem",
                          color: "#6f42c1",
                        }}
                      />
                    </td>
                    <td onClick={(e) => handleDelClick(e, val.id)}>
                      <MdDelete
                        style={{
                          fontSize: "1rem",
                          color: "red",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <i style={{ color: "#6e46c9" }} className="fas fa-angle-left"></i>
            }
            nextLabel={
              <i
                style={{ color: "#6e46c9" }}
                className="fas fa-angle-right"
              ></i>
            }
            breakLabel={"..."}
            pageCount={numberOfPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      )}
    </>
  );
};

export default SuperStoreOwners;
