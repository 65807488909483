import { configureStore } from "@reduxjs/toolkit";
import * as StoreAdminReducer from "./StoreAdminAuth/StoreAdminReducer";
import * as SalesReducer from "./StoreAdminPannel/SalesReducer";
import * as Toggle from "./SidebarComponent/SidebarReducer";
import * as salesToken from "./SalesAuthToken/StoreAdminReducer";
import * as dateRange from "./DateRange/DateRangeReducer";
import * as LoginLogout from "./LoginLogout/LoginLogoutReducer";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import SuperAdminReducer from "./ToolkitReducers/SuperAdminReducer";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
};

const reducer = combineReducers({
  storeAdminLogin: StoreAdminReducer.reducer,
  salesData: SalesReducer.reducer,
  toggle: Toggle.reducer,
  salesToken: salesToken.reducer,
  dateRange: dateRange.reducer,
  LoginLogout: LoginLogout.reducer,
  superAdminData: SuperAdminReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});
export default store;
